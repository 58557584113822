var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
var BarGraph = function (_a) {
    var leads = _a.leads, _b = _a.loading, loading = _b === void 0 ? false : _b, salesDate = _a.salesDate;
    var _c = useState({}), chartData = _c[0], setChartData = _c[1];
    var chart = function () {
        setChartData({
            labels: leads ? leads === null || leads === void 0 ? void 0 : leads.labels : [],
            datasets: [
                {
                    label: 'Total Leads',
                    data: leads ? leads === null || leads === void 0 ? void 0 : leads.totalLeads : [],
                    borderColor: 'rgba(109, 196, 234, 1)',
                    backgroundColor: 'rgba(109, 196, 234,1)',
                    lineTension: 0.5,
                    borderWidth: 5,
                    barPercentage: !(salesDate === null || salesDate === void 0 ? void 0 : salesDate.start_date) ? 0.5 : 0.5,
                    categoryPercentage: 0.2,
                    pointRadius: 5,
                    pointHoverRadius: 8,
                },
                {
                    label: 'Total Leads Converted',
                    data: leads ? leads === null || leads === void 0 ? void 0 : leads.converted : [],
                    borderColor: 'rgba(255, 196, 83, 1)',
                    backgroundColor: 'rgba(255, 196, 83, 1)',
                    lineTension: 0.5,
                    borderWidth: 5,
                    barPercentage: !(salesDate === null || salesDate === void 0 ? void 0 : salesDate.start_date) ? 0.5 : 0.5,
                    categoryPercentage: 0.2,
                    pointRadius: 5,
                    pointHoverRadius: 8,
                },
            ],
        });
    };
    useEffect(function () {
        chart();
    }, [leads]);
    var options = {
        responsive: true,
        legend: {
            display: false, // set to false to remove the legend
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                    gridLines: {
                        display: true,
                        borderDash: [5, 5],
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                        borderDash: [5, 5],
                    },
                },
            ],
        },
        onHover: function (event, chartElement) {
            event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            if (chartElement[0]) {
                chartElement[0]._model.radius = chartElement[0]._model.pointRadius + 5;
            }
        },
    };
    return (_jsx("div", __assign({ style: { position: 'relative', height: 'auto', width: '100%' } }, { children: loading ? (_jsx("div", __assign({ className: 'w-full h-96' }, { children: _jsx("p", __assign({ className: 'font-nunitoBold' }, { children: "loading.." })) }))) : (_jsx(Bar, { data: chartData, options: options })) })));
};
export default BarGraph;
