var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../../middleware/api-creators';
var initialState = {
    qrcodeList: [],
    isLoading: true,
};
export var qrcodeSlice = createSlice({
    name: 'qrcode',
    initialState: initialState,
    reducers: {
        QrcodesListSuccess: function (state, action) {
            var _a, _b, _c, _d;
            console.log('action:', (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data);
            state.isLoading = false;
            state.qrcodeList = ((_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.data) || [];
        },
        QrcodesListFailed: function (state) {
            state.isLoading = false;
        },
        QrcodesListPending: function (state) {
            state.isLoading = true;
        },
    },
});
export var QrcodesListPending = (_a = qrcodeSlice.actions, _a.QrcodesListPending), QrcodesListSuccess = _a.QrcodesListSuccess, QrcodesListFailed = _a.QrcodesListFailed;
export default qrcodeSlice.reducer;
export var fetchQrsList = function () {
    return apiCallBegan({
        url: "/admin/vendor-qrs",
        method: 'GET',
        onSuccess: QrcodesListSuccess.type,
        onError: QrcodesListFailed.type,
        onStart: QrcodesListPending.type,
    });
};
