var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
var initialState = {
    supportsList: [],
    ticketById: {},
    isLoading: false,
    metadata: {
        totalPages: 0,
        total: 0,
        totalTickets: 0,
    },
    supportlogs: [],
    logsmeta: {
        totalLogs: 0,
        totalPages: 0,
        total: 0,
    },
    analytics: {},
};
export var supportticketSlice = createSlice({
    name: 'supports',
    initialState: initialState,
    reducers: {
        resetLoading: function (state) {
            state.isLoading = false;
        },
        SupportListPending: function (state) {
            state.isLoading = true;
        },
        SupportListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            state.isLoading = false;
            state.supportsList = ((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data) || [];
            state.metadata.totalPages = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.last_page;
            state.metadata.total = (_h = (_g = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.meta) === null || _h === void 0 ? void 0 : _h.total;
            state.metadata.totalTickets = (_j = action === null || action === void 0 ? void 0 : action.payload) === null || _j === void 0 ? void 0 : _j.total;
        },
        SupportListFailed: function (state) {
            state.isLoading = false;
        },
        TicketFechedSuccess: function (state, action) {
            var _a;
            state.isLoading = false;
            state.ticketById = ((_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) || {};
        },
        TicketPending: function (state) {
            state.isLoading = true;
        },
        TicketFechedFailed: function (state, action) {
            state.isLoading = false;
        },
        AnalyticsPending: function (state, action) {
            state.isLoading = true;
        },
        AnalyticsSuccess: function (state, action) {
            var _a;
            state.isLoading = false;
            state.analytics = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
        },
        AnalyticsFailed: function (state, action) {
            state.isLoading = false;
        },
        LogsPending: function (state, action) { },
        LogsSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            state.supportlogs = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data;
            console.log('fetchsupportlogs', (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta);
            state.logsmeta.totalPages = (_f = (_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.data.meta) === null || _f === void 0 ? void 0 : _f.last_page;
            state.logsmeta.total = (_j = (_h = (_g = action === null || action === void 0 ? void 0 : action.payload) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.meta) === null || _j === void 0 ? void 0 : _j.total;
            state.logsmeta.totalLogs = (_m = (_l = (_k = action === null || action === void 0 ? void 0 : action.payload) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.meta) === null || _m === void 0 ? void 0 : _m.total;
        },
        LogsFailed: function (state, action) { },
    },
});
export var resetLoading = (_a = supportticketSlice.actions, _a.resetLoading), SupportListSuccess = _a.SupportListSuccess, SupportListFailed = _a.SupportListFailed, TicketFechedFailed = _a.TicketFechedFailed, TicketFechedSuccess = _a.TicketFechedSuccess, SupportListPending = _a.SupportListPending, TicketPending = _a.TicketPending, AnalyticsPending = _a.AnalyticsPending, AnalyticsSuccess = _a.AnalyticsSuccess, AnalyticsFailed = _a.AnalyticsFailed, LogsPending = _a.LogsPending, LogsSuccess = _a.LogsSuccess, LogsFailed = _a.LogsFailed;
export default supportticketSlice.reducer;
export var fetchSupportsList = function (data) {
    return apiCallBegan({
        url: "/admin/support-ticket?page=".concat(data === null || data === void 0 ? void 0 : data.page, "&executive_id=").concat(data === null || data === void 0 ? void 0 : data.executive_id, "&priority=").concat(data === null || data === void 0 ? void 0 : data.priority, "&status=").concat(data === null || data === void 0 ? void 0 : data.status, "&search_key=").concat(data === null || data === void 0 ? void 0 : data.search_key, "&start_date=").concat(data === null || data === void 0 ? void 0 : data.start_date, "&end_date=").concat(data === null || data === void 0 ? void 0 : data.end_date, "&assign_status=").concat(data.assign_status),
        method: 'GET',
        onStart: SupportListPending.type,
        onSuccess: SupportListSuccess.type,
        onError: SupportListFailed.type,
    });
};
export var getSupportTicketById = function (id) {
    return apiCallBegan({
        url: "/admin/support-ticket/".concat(id),
        method: 'GET',
        onStart: TicketPending.type,
        onSuccess: TicketFechedSuccess.type,
        onError: TicketFechedFailed.type,
    });
};
export var getSupportAnalytics = function (data) {
    return apiCallBegan({
        url: "/admin/support-ticket/stats?start_date=".concat(data === null || data === void 0 ? void 0 : data.start_date, "&end_date=").concat(data === null || data === void 0 ? void 0 : data.end_date),
        method: 'GET',
        onSuccess: AnalyticsSuccess.type,
        onError: AnalyticsFailed.type,
    });
};
export var getSupportLogs = function (data) {
    return apiCallBegan({
        url: "/admin/support-ticket/logs/".concat((data === null || data === void 0 ? void 0 : data.id) || '', "?page=").concat(data.page || '', "&start_date=").concat((data === null || data === void 0 ? void 0 : data.start_date) || '', "&end_date=").concat((data === null || data === void 0 ? void 0 : data.end_date) || ''),
        method: 'GET',
        onStart: LogsPending.type,
        onSuccess: LogsSuccess.type,
        onError: LogsFailed.type,
    });
};
