var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
var initialState = {
    testsList: [],
    isLoading: true,
    metadata: {
        totalTests: 0,
        totalPages: 0,
        total: 0,
    },
};
export var testsSlice = createSlice({
    name: 'tests',
    initialState: initialState,
    reducers: {
        resetLoading: function (state) {
            state.isLoading = true;
        },
        TestListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            console.log('action:', action.payload);
            state.isLoading = false;
            state.testsList = action.payload.data.data || [];
            state.metadata.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.metadata.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.metadata.totalTests = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_tests;
        },
        TestListFailed: function (state) {
            state.isLoading = false;
        },
    },
});
export var resetLoading = (_a = testsSlice.actions, _a.resetLoading), TestListSuccess = _a.TestListSuccess, TestListFailed = _a.TestListFailed;
export default testsSlice.reducer;
export var fetchTestsList = function (data, page) {
    return apiCallBegan({
        url: "/admin/tests?search_key=".concat(data.search_key.trim(), "&start_date=").concat(data.start_date, "&end_date=").concat(data.end_date, "&page=").concat(page),
        method: 'GET',
        onSuccess: TestListSuccess.type,
        onError: TestListFailed.type,
    });
};
