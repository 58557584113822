var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ZainMainLogo from '../assets/images/ZainLogos/ZainMainLogo.svg';
import ZainTitle from '../assets/images/ZainLogos/ZainTitle.svg';
import scube from '../assets/images/scube.svg';
var Authenticated = function (_a) {
    var children = _a.children;
    return (_jsx("div", __assign({ className: 'relative ' }, { children: _jsxs("div", __assign({ className: 'flex justify-between h-screen' }, { children: [_jsxs("div", __assign({ className: ' hidden md:flex bg-lightGray w-full gap-2 justify-center  md:w-7/12' }, { children: [_jsx("img", { className: ' w-1/5', src: ZainMainLogo, alt: 'zainmain_logo' }), _jsx("img", { className: ' w-1/3', src: ZainTitle, alt: 'zain_title' })] })), _jsxs("div", __assign({ className: 'w-full md:w-5/12 ' }, { children: [_jsx("div", __assign({ className: ' w-full rounded-lg bg-lightGray md:bg-white flex items-center flex-col md:flex-row justify-center   h-screen' }, { children: children })), _jsxs("p", __assign({ className: 'w-inherit absolute bottom-1 flex gap-2  items-center text-yellow font-nunitoLight text-xs justify-center ' }, { children: ["Powered By", _jsx("span", { children: _jsx("img", { className: ' relative bottom-[2px] w-[78px]', src: scube, alt: 'scubelogo' }) })] }))] }))] })) })));
};
export default Authenticated;
