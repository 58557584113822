var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '../../../../../assets/icons/closeSquare.svg';
import { SelectInput } from '../../../../Common/Input/Select';
import CustomButton from '../../../../Common/Button';
import { Input } from '../User_Roles/common/Input';
var CheckListModal = function (_a) {
    var open = _a.open, handleClose = _a.handleClose, type = _a.type, params = _a.params, handleChange = _a.handleChange, onSubmit = _a.onSubmit, isLoading = _a.isLoading, label = _a.label, errors = _a.errors;
    return (_jsx("div", __assign({ className: 'w-full' }, { children: _jsxs(Dialog, __assign({ PaperProps: { sx: { width: '100%', m: 2 } }, open: open, onClose: handleClose, "aria-labelledby": 'alert-dialog-title', "aria-describedby": 'alert-dialog-description', sx: {
                width: '100%',
                padding: 0,
                borderRadius: '8px',
                '& .MuiPaper-root': {
                    border: '1px solid #E7E8ED !important',
                    'border-radius': '8px !important',
                },
            } }, { children: [_jsx(DialogTitle, __assign({ id: 'alert-dialog-title', sx: { background: '#FFFFFF' } }, { children: _jsxs("div", __assign({ className: 'flex justify-between audio text-[#141C4C]' }, { children: [type === 'update' && (_jsx("div", __assign({ className: 'flex flex-col mr-2' }, { children: _jsx("p", __assign({ className: 'text-[20px] text-[#141C4C] font-nunitoBold' }, { children: "Update Checklist" })) }))), type === 'create' && (_jsx("div", __assign({ className: 'flex flex-col mr-2' }, { children: _jsx("p", __assign({ className: 'text-[18px] text-[#141C4C] font-nunitoBold' }, { children: "Add Checklist" })) }))), _jsx("div", __assign({ className: 'cursor-pointer', onClick: handleClose }, { children: _jsx("img", { src: CloseIcon, alt: '' }) }))] })) })), _jsx(DialogContent, __assign({ sx: { background: '#FFFFFF' } }, { children: _jsx(DialogContentText, __assign({ id: 'alert-dialog-description' }, { children: _jsxs("div", __assign({ className: 'w-full bg-tab text-[#141C4C] p-6 rounded-lg flex flex-col gap-6 bg-CalmWaters' }, { children: [_jsx(Input, { rows: 1, width: 'w-full', disabled: false, readOnly: false, value: params === null || params === void 0 ? void 0 : params.name, error: !!(errors === null || errors === void 0 ? void 0 : errors.name), helperText: errors === null || errors === void 0 ? void 0 : errors.name, handleChange: handleChange, label: 'Name*', name: 'name', bgcolor: 'transparent' }), _jsx(SelectInput, { width: '100%', options: [
                                        { id: 'Yes', name: 'Yes' },
                                        { id: 'No', name: 'No' },
                                    ], value: params === null || params === void 0 ? void 0 : params.is_mandatory, error: !!(errors === null || errors === void 0 ? void 0 : errors.is_mandatory), helperText: errors === null || errors === void 0 ? void 0 : errors.is_mandatory, handleChange: handleChange, label: 'Mandatory or Not*', name: 'is_mandatory' })] })) })) })), _jsx(DialogActions, __assign({ sx: { background: '#FFFFFF' } }, { children: _jsx("div", __assign({ className: 'w-full flex justify-center pb-6' }, { children: isLoading ? (_jsx(CustomButton, __assign({ variant: 'contained', size: 'large', borderRadius: '8px', width: 'w-fit' }, { children: _jsx("p", __assign({ className: '' }, { children: "Loading..." })) }))) : (_jsx(CustomButton, __assign({ variant: 'contained', size: 'large', borderRadius: '8px', width: 'w-fit', onClick: onSubmit }, { children: _jsx("p", __assign({ className: '' }, { children: label })) }))) })) }))] })) })));
};
export default CheckListModal;
