var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { toast } from 'react-toastify';
var showToastMessage = function (message, type) {
    if (type === 'error') {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    else {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
};
var initialState = {
    rolesList: [],
    deleteStatus: false,
    isCreated: false,
    isLoading: false,
    modulesList: [],
    // metadata: {
    //     totalLabs: 0,
    //     totalPages: 0,
    //     total: 0,
    // },
    total_roles: 0,
};
export var settingSlice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        CreateRolePending: function (state, action) {
            state.isLoading = true;
            state.isCreated = false;
        },
        CreateRoleSuccess: function (state, action) {
            var _a, _b;
            state.isLoading = false;
            state.isCreated = true;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'success');
        },
        CreateRoleFailure: function (state, action) {
            var _a = action === null || action === void 0 ? void 0 : action.payload, errors = _a.errors, message = _a.message;
            var erroMsg = errors && errors[Object.keys(errors)[0]] || message;
            state.isLoading = false;
            showToastMessage(erroMsg, 'error');
        },
        RolesListPending: function (state, action) {
            state.isLoading = true;
        },
        RolesListSuccess: function (state, action) {
            var _a, _b;
            state.isLoading = false;
            state.deleteStatus = false;
            state.isCreated = false;
            console.log('user role data from slice', action.payload);
            state.rolesList = action.payload.data.data || [];
            state.modulesList = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.modules;
            // state.metadata.totalPages = action?.payload?.data.meta?.last_page
            // state.metadata.total = action?.payload?.data?.meta?.total
            // state.metadata.totalLabs = action?.payload?.total_roles
        },
        RolesListFailure: function (state, action) {
            var _a, _b;
            state.isLoading = false;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'error');
        },
        DeletePending: function (state, action) { },
        DeleteSuccess: function (state, action) {
            var _a, _b, _c;
            // showToastMessage(action.payload.data.message, 'success')
            console.log(' user role deleted', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            state.deleteStatus = true;
            showToastMessage((_c = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message, 'success');
        },
        DeleteFailure: function (state, action) {
            var _a;
            state.deleteStatus = false;
            console.log('delete failed', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage("You can't delete the role. Its associate with multiple applicants", 'error');
        },
    },
});
export var CreateRolePending = (_a = settingSlice.actions, _a.CreateRolePending), CreateRoleSuccess = _a.CreateRoleSuccess, CreateRoleFailure = _a.CreateRoleFailure, RolesListPending = _a.RolesListPending, RolesListSuccess = _a.RolesListSuccess, RolesListFailure = _a.RolesListFailure, DeletePending = _a.DeletePending, DeleteSuccess = _a.DeleteSuccess, DeleteFailure = _a.DeleteFailure;
export default settingSlice.reducer;
export var createAdminRole = function (data) {
    return apiCallBegan({
        url: '/admin/roles',
        method: 'POST',
        data: data,
        onStart: CreateRolePending.type,
        onSuccess: CreateRoleSuccess.type,
        onError: CreateRoleFailure.type,
    });
};
// list of roles
export var getAdminRolesList = function () {
    return apiCallBegan({
        url: '/admin/roles',
        method: 'GET',
        onStart: RolesListPending.type,
        onSuccess: RolesListSuccess.type,
        onError: RolesListFailure.type,
    });
};
export var deleteRoleById = function (id) {
    return apiCallBegan({
        url: "/admin/roles/".concat(id),
        method: 'DELETE',
        onStart: DeletePending.type,
        onSuccess: DeleteSuccess.type,
        onError: DeleteFailure.type,
    });
};
//update admin role permissions
export var updateAdminRolePermissions = function (id, data) {
    return apiCallBegan({
        url: "/admin/roles/".concat(id),
        method: 'PUT',
        data: data,
        onStart: CreateRolePending.type,
        onSuccess: CreateRoleSuccess.type,
        onError: CreateRoleFailure.type,
    });
};
