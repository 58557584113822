var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from './../../utils/helpers';
var initialState = {
    hubsList: [],
    isHubsListLoading: false,
    metadata: {
        totalHubs: 0,
        totalPages: 0,
        total: 0,
    },
    totalHubs: 0,
    createStatus: false,
    isLoading: false,
    deleteStatus: false,
};
export var hubSlice = createSlice({
    name: 'hubs',
    initialState: initialState,
    reducers: {
        CreateHubPending: function (state, action) { },
        CreateHubSuccess: function (state, action) {
            var _a, _b;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'success');
            state.createStatus = true;
        },
        CreateHubFailure: function (state, action) {
            var _a, _b;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'error');
            state.createStatus = false;
        },
        HubsListPending: function (state, action) {
            state.isHubsListLoading = true;
        },
        HubsListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            state.isHubsListLoading = false;
            state.deleteStatus = false;
            state.hubsList = action.payload.data.data || [];
            state.metadata.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.metadata.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.metadata.totalHubs = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_hubs;
        },
        HubsListFailure: function (state, action) {
            var _a;
            console.log('failed to get hubs list', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
        },
        UpdateHubPending: function (state, action) { },
        UpdateHubSuccess: function (state, action) {
            var _a, _b, _c;
            console.log('hub record updated successfully', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage((_c = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message, 'success');
        },
        UpdateHubFailure: function (state, action) {
            var _a, _b, _c;
            console.log('failed to update hub record', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage((_c = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message, 'error');
        },
        DeleteHubSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'success');
            state.deleteStatus = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.status;
            showToastMessage((_f = (_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.message, 'success');
        },
        DeletePending: function (state, action) { },
        DeleteSuccess: function (state, action) {
            var _a, _b, _c;
            // showToastMessage(action.payload.data.message, 'success')
            console.log('deleted job opening', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            state.deleteStatus = (_c = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.status;
        },
        DeleteFailure: function (state, action) {
            var _a, _b, _c;
            state.deleteStatus = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.status;
            console.log('delete job opening by id', (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data);
            showToastMessage("You can't delete the role. Its associate with multiple applicants", 'error');
        },
    },
});
export var CreateHubPending = (_a = hubSlice.actions, _a.CreateHubPending), CreateHubSuccess = _a.CreateHubSuccess, CreateHubFailure = _a.CreateHubFailure, HubsListPending = _a.HubsListPending, HubsListSuccess = _a.HubsListSuccess, HubsListFailure = _a.HubsListFailure, DeleteHubPending = _a.DeleteHubPending, DeleteHubSuccess = _a.DeleteHubSuccess, DeleteHubFailure = _a.DeleteHubFailure, UpdateHubPending = _a.UpdateHubPending, UpdateHubSuccess = _a.UpdateHubSuccess, UpdateHubFailure = _a.UpdateHubFailure, DeletePending = _a.DeletePending, DeleteSuccess = _a.DeleteSuccess, DeleteFailure = _a.DeleteFailure;
export default hubSlice.reducer;
//CREATE HUB
export var createHub = function (data) {
    return apiCallBegan({
        url: '/admin/hubs',
        method: 'POST',
        data: data,
        onStart: CreateHubPending.type,
        onSuccess: CreateHubSuccess.type,
        onError: CreateHubFailure.type,
    });
};
//GET HUBS LISTING
export var getHubsList = function (params) {
    return apiCallBegan({
        url: "/admin/hubs?page=".concat(params.page, "&search_key=").concat(params.search_key.trim(), "&manager_id=").concat(params.manager_id),
        method: 'GET',
        onStart: HubsListPending.type,
        onSuccess: HubsListSuccess.type,
        onError: HubsListFailure.type,
    });
};
export var updateHubRecord = function (data, id) {
    return apiCallBegan({
        url: "/admin/hubs/".concat(id),
        method: 'PUT',
        data: data,
        onStart: UpdateHubPending.type,
        onSuccess: UpdateHubSuccess.type,
        onError: UpdateHubFailure.type,
    });
};
export var deleteHubById = function (id) {
    return apiCallBegan({
        url: "/admin/hubs/".concat(id),
        method: 'DELETE',
        onStart: DeletePending.type,
        onSuccess: DeleteSuccess.type,
        onError: DeleteFailure.type,
    });
};
