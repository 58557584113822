var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import closeSquare from '../../../../../assets/icons/closeSquare.svg';
import CustomButton from './common/Button';
import { Input } from './common/Input';
import RadioButton from './common/RadioButton';
import ModulesAccess from './ModuleAccess';
import { useSelector } from 'react-redux';
var BootstrapDialog = styled(Dialog)(function (_a) {
    var theme = _a.theme;
    return ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
            width: '100%',
            alignItems: 'center',
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            backgroundColor: '#404050',
        },
        dialogCustomizedWidth: {
            'max-width': '80%',
        },
    });
});
var items = [
    {
        value: 'Manager',
        label: 'Manager',
    },
    {
        value: 'Executive',
        label: 'Executive',
    },
];
var BootstrapDialogTitle = function (props) {
    var children = props.children, onClose = props.onClose, type = props.type, other = __rest(props, ["children", "onClose", "type"]);
    var closeModel = function () {
        onClose();
    };
    return (_jsxs(DialogTitle, __assign({ sx: { m: 0, p: 3 } }, other, { children: [children, _jsx(IconButton, __assign({ "aria-label": "close", onClick: closeModel, sx: {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: function (theme) { return theme.palette.grey[600]; },
                } }, { children: _jsx("img", { src: closeSquare, alt: "" }) }))] })));
};
var CustomizedDialogs = function (_a) {
    var open = _a.open, handleClose = _a.handleClose, title = _a.title, type = _a.type, name = _a.name, submit = _a.submit, setParams = _a.setParams, params = _a.params, handleRootChange = _a.handleRootChange, handleChildChange = _a.handleChildChange, handleChange = _a.handleChange, formErrors = _a.formErrors;
    var isLoading = useSelector(function (state) { return state.settings.isLoading; });
    return (_jsx("div", { children: _jsxs(BootstrapDialog, __assign({ sx: {
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    backdropFilter: 'blur(5px)',
                },
            }, "aria-labelledby": "customized-dialog-title", open: open, PaperProps: {
                sx: {
                    width: window.innerWidth < 768 ? window.innerWidth < 648 ? '100%' : '90%' : '80%',
                    m: 0,
                    backgroundColor: 'arsenic',
                    alignItems: 'center',
                    borderColor: 'arsenic',
                },
            } }, { children: [_jsx(BootstrapDialogTitle, __assign({ id: name, onClose: handleClose, type: type }, { children: _jsx("div", __assign({ className: "flex justify-start" }, { children: _jsx("p", __assign({ className: "font-bold font-nunitoRegular flex justify-start absolute top-2 left-6" }, { children: title })) })) })), _jsx(DialogContent, { children: _jsx("div", __assign({ className: "flex flex-col mt-2 gap-10  rounded-2" }, { children: _jsxs("div", __assign({ className: "w-full  bg-CalmWaters p-6 rounded-lg flex flex-col gap-6" }, { children: [_jsx(Input, { rows: 1, width: "w-full", disabled: false, readOnly: false, error: !!formErrors.name, value: params === null || params === void 0 ? void 0 : params.name, handleChange: handleChange, helperText: formErrors.name, label: "Enter the User Role*", name: "name" }), _jsx("div", __assign({ className: "default_container" }, { children: _jsxs("div", { children: [_jsx("p", __assign({ className: "text-s font-nunitoRegular mb-2" }, { children: _jsx("u", { children: "Select the level that user can Access:" }) })), _jsx(RadioButton, { onChange: setParams, items: items, defaultValue: params.role })] }) })), _jsxs("div", __assign({ className: "default_container" }, { children: [_jsx("div", { children: _jsx("p", __assign({ className: "text-s font-nunitoRegular mb-2" }, { children: _jsx("u", { children: "Select the Module that user can Access:" }) })) }), _jsx(ModulesAccess, { params: params, handleRootChange: handleRootChange, handleChildChange: handleChildChange })] }))] })) })) }), _jsx("div", __assign({ className: "flex justify-center pt-2 pb-10 h-[72px]" }, { children: _jsx(CustomButton, __assign({ disabled: isLoading, onClick: submit, width: "w-full", variant: "contained", size: "large" }, { children: params.id ? ' Update Details ' : 'Submit Details' })) }))] })) }));
};
export default CustomizedDialogs;
