var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from './../../utils/helpers';
var initialState = {
    invoiceList: [],
    totalAccounts: '',
    isLoading: false,
    meta: {
        total: 0
    },
    stats: {}
};
export var accountSlice = createSlice({
    name: 'accounts',
    initialState: initialState,
    reducers: {
        formReset: function () { },
        resetLoading: function (state) {
            state.isLoading = true;
        },
        SuccessfulList: function (state, action) {
            var _a, _b, _c;
            // console.log(action.payload.total, 'profile...')
            state.invoiceList = action === null || action === void 0 ? void 0 : action.payload.data.data;
            state.totalAccounts = action === null || action === void 0 ? void 0 : action.payload.total;
            state.isLoading = false;
            state.meta.total = ((_c = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.total) || 0;
        },
        AccListPending: function (state) {
            state.isLoading = true;
        },
        AccListFailure: function (state, action) {
            showToastMessage(action.payload.data.message, 'error');
        },
        StatsPending: function (state, action) { },
        StatsSuccess: function (state, action) {
            var _a, _b;
            console.log('stats data from accounts slice', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            state.stats = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data;
        },
        StatsFailure: function (state, action) {
            console.log('unable to fetch stats info');
        },
    },
});
export var formReset = (_a = accountSlice.actions, _a.formReset), resetLoading = _a.resetLoading, SuccessfulList = _a.SuccessfulList, AccListPending = _a.AccListPending, AccListFailure = _a.AccListFailure, StatsPending = _a.StatsPending, StatsSuccess = _a.StatsSuccess, StatsFailure = _a.StatsFailure;
export default accountSlice.reducer;
export var fetchAccounts = function (params) {
    return apiCallBegan({
        url: "/admin/invoice?page=".concat(params === null || params === void 0 ? void 0 : params.page, "&start_date=").concat(params.start_date, "&end_date=").concat(params.end_date, "&search_key=").concat(params.search_key, "&status=").concat(params.status),
        method: 'GET',
        onStart: AccListPending.type,
        onSuccess: SuccessfulList.type,
        onError: AccListFailure.type,
    });
};
export var getStatsInfo = function () {
    return apiCallBegan({
        url: "/admin/invoice/stats",
        method: 'GET',
        onStart: StatsPending.type,
        onSuccess: StatsSuccess.type,
        onError: StatsFailure.type,
    });
};
