var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '../../../assets/icons/closeSquare.svg';
import CustomButton from '../../Common/Button';
import TextArea from "../../Common/Input/TextArea";
var AddEditFaq = function (_a) {
    var open = _a.open, handleClose = _a.handleClose, type = _a.type, handleSubmit = _a.handleSubmit, handleChange = _a.handleChange, params = _a.params, formErrors = _a.formErrors, disabled = _a.disabled;
    return (_jsx("div", __assign({ className: "w-full" }, { children: _jsxs(Dialog, __assign({ PaperProps: { sx: { width: "100%", m: 2 } }, open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", sx: {
                width: "100%",
                padding: 0,
                borderRadius: "8px",
                "& .MuiPaper-root": {
                    border: "1px solid #E7E8ED !important",
                    "border-radius": "8px !important",
                },
            } }, { children: [_jsx(DialogTitle, __assign({ id: "alert-dialog-title", sx: { background: "#FFFFFF" } }, { children: _jsxs("div", __assign({ className: "flex justify-between" }, { children: [_jsx("p", __assign({ className: "text-lg text-[#141C4C] font-nunitoBold" }, { children: type != '' ? 'Edit FAQ' : 'Add FAQ' })), _jsx("div", __assign({ className: "cursor-pointer", onClick: handleClose }, { children: _jsx("img", { src: CloseIcon, alt: "" }) }))] })) })), _jsx(DialogContent, __assign({ sx: { background: "#FFFFFF" } }, { children: _jsx(DialogContentText, __assign({ id: "alert-dialog-description" }, { children: _jsxs("div", __assign({ className: "w-full bg-tab text-[#141C4C] p-6 rounded-lg flex flex-col gap-4 bg-CalmWaters" }, { children: [_jsx(TextArea, { rows: 3, handleChange: handleChange, name: 'query', value: params === null || params === void 0 ? void 0 : params.query, placeholder: 'Question', error: formErrors === null || formErrors === void 0 ? void 0 : formErrors.query, helperText: formErrors === null || formErrors === void 0 ? void 0 : formErrors.query }), _jsx(TextArea, { rows: 5, handleChange: handleChange, name: 'solution', value: params === null || params === void 0 ? void 0 : params.solution, placeholder: 'Answer', error: formErrors === null || formErrors === void 0 ? void 0 : formErrors.solution, helperText: formErrors === null || formErrors === void 0 ? void 0 : formErrors.solution })] })) })) })), _jsx(DialogActions, __assign({ sx: { background: "#FFFFFF" } }, { children: _jsx("div", __assign({ className: "w-full flex justify-center pb-6" }, { children: _jsx(CustomButton, __assign({ variant: "contained", size: "large", borderRadius: "8px", width: "w-fit", onClick: handleSubmit, disabled: disabled }, { children: _jsx("p", __assign({ className: "" }, { children: type != '' ? 'Update' : 'Submit' })) })) })) }))] })) })));
};
export default AddEditFaq;
