var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';
import { uuid } from '../../../utils/helpers';
export var Pagination = function (props) {
    var onPageChange = props.onPageChange, totalCount = props.totalCount, _a = props.siblingCount, siblingCount = _a === void 0 ? 1 : _a, currentPage = props.currentPage, pageSize = props.pageSize, className = props.className;
    var paginationRange = usePagination({
        currentPage: currentPage,
        totalCount: totalCount,
        siblingCount: siblingCount,
        pageSize: pageSize,
    });
    var _b = useState(''), customPageNo = _b[0], setcustomPageNo = _b[1];
    if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
        return null;
    }
    var onNext = function () {
        onPageChange(currentPage + 1);
    };
    var onPrevious = function () {
        onPageChange(currentPage - 1);
    };
    var handleKeyDown = function (event) {
        if (event.key === 'Enter'
            && Number(customPageNo) > 0
            && paginationRange
            && Number(customPageNo) <= paginationRange[paginationRange.length - 1]) {
            onPageChange(Number(customPageNo));
        }
    };
    var lastPage = paginationRange && paginationRange[paginationRange.length - 1];
    var handleInputChange = function (event) {
        var value = event.target.value;
        if (value && Number(value) === 0)
            return;
        var re = /^[0-9+]+$/;
        if ((value && !re.test(value))
            || (paginationRange && Number(value) > paginationRange[paginationRange.length - 1])) {
            return;
        }
        setcustomPageNo(value);
    };
    return (_jsxs("div", __assign({ className: "flex space-x-4  justify-center" }, { children: [_jsxs("ul", __assign({ className: "pagination-container ".concat(className) }, { children: [_jsx("li", __assign({ className: "arrow-icon pagination-item ".concat(currentPage === 1 ? 'disabled' : ''), onClick: onPrevious }, { children: _jsx("div", { className: "arrow left" }) }), uuid()), paginationRange === null || paginationRange === void 0 ? void 0 : paginationRange.map(function (pageNumber) {
                        if (pageNumber === DOTS) {
                            return (_jsx("li", __assign({ className: "pagination-item dots" }, { children: "\u2026" }), uuid()));
                        }
                        return (_jsx("li", __assign({ className: "pagination-item ".concat(pageNumber === currentPage ? 'selected' : ''), onClick: function () { return onPageChange(pageNumber); } }, { children: pageNumber }), uuid()));
                    }), _jsx("li", __assign({ className: "arrow-icon pagination-item ".concat(currentPage === lastPage ? 'disabled' : ''), onClick: onNext }, { children: _jsx("div", { className: "arrow right" }) }), uuid())] })), _jsxs("div", __assign({ className: "hidden lg:flex space-x-2 justify-center items-center text-SpaceCadet pagination-goto relative" }, { children: [_jsx("p", __assign({ className: "text-sm" }, { children: "Go to Page:" })), _jsx("div", __assign({ className: "h-[40px] w-[84px] pagination-input-sec relative px-2 lg:flex justify-center items-center rounded-md border border-[#141C4C]  bg-transparent hover:border-[2px] hover:border-[#0C8EC7]  hidden" }, { children: _jsx("input", { type: "text", value: customPageNo, onChange: handleInputChange, onKeyDown: handleKeyDown, className: "h-full relative\n            w-full outline-none  focus:outline-none hover:outline-none pl-1 rounded-md bg-transparent" }) }))] }))] })));
};
export default Pagination;
