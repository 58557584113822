var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from './../../utils/helpers';
var initialState = {
    testsGroupsList: [],
    isLoading: false,
    isSuccess: false,
    metadata: {
        totalTestsGroups: 0,
        totalPages: 0,
        total: 0,
    },
    // ------------
    total_test_groups: 0,
    testGroupList: [],
    //   isLoading: false,
    addSuccess: false,
    testGroupById: {},
};
export var testGroupSlice = createSlice({
    name: 'testgroup',
    initialState: initialState,
    reducers: {
        TestGroupListPending: function (state, action) {
            state.isLoading = true;
        },
        resetSuccess: function (state, action) {
            state.isSuccess = false;
        },
        TestGroupListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            console.log('action:', action.payload);
            state.isLoading = false;
            state.testsGroupsList = action.payload.data.data || [];
            state.metadata.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.metadata.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.metadata.totalTestsGroups = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_test_groups;
            // -------------------
        },
        TestGroupListFailure: function (state, action) {
            var _a;
            state.isLoading = false;
            console.log('fetched failed', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
        },
        TestGroupPending: function (state, action) { },
        TestGroupSuccess: function (state, action) {
            var _a, _b, _c;
            console.log('test group by id', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            state.testGroupById = (_c = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.data;
        },
        TestGroupFailure: function (state, action) {
            var _a;
            console.log('failed to fetch test group by id', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
        },
        CreateTestGroupPending: function (state, action) { },
        CreateTestGroupSuccess: function (state, action) {
            var _a;
            console.log('test group created', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage(action.payload.data.message, 'success');
            state.addSuccess = true;
            state.isSuccess = true;
        },
        CreateTestGroupFailure: function (state, action) {
            state.isSuccess = true;
            // showToastMessage(action.payload.data.message, 'error')
        },
        UpdateTestGroupPending: function (state, action) { },
        UpdateTestGroupSuccess: function (state, action) {
            var _a;
            console.log('test group updated', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage(action.payload.data.message, 'success');
            state.addSuccess = true;
            state.isSuccess = true;
        },
        UpdateTestGroupFailure: function (state, action) {
            state.addSuccess = false;
            state.isSuccess = false;
        },
        DeleteTestGroupPending: function (state, action) { },
        DeleteTestGroupSuccess: function (state, action) {
            var _a;
            console.log('test group deleted', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage(action.payload.data.message, 'success');
        },
        DeleteTestGroupFailure: function (state, action) {
            var _a;
            console.log('deletion failed', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage(action.payload.data.message, 'error');
        },
    },
});
export default testGroupSlice.reducer;
export var resetSuccess = (_a = testGroupSlice.actions, _a.resetSuccess), TestGroupListPending = _a.TestGroupListPending, TestGroupListSuccess = _a.TestGroupListSuccess, TestGroupListFailure = _a.TestGroupListFailure, TestGroupPending = _a.TestGroupPending, TestGroupSuccess = _a.TestGroupSuccess, TestGroupFailure = _a.TestGroupFailure, CreateTestGroupPending = _a.CreateTestGroupPending, CreateTestGroupSuccess = _a.CreateTestGroupSuccess, CreateTestGroupFailure = _a.CreateTestGroupFailure, UpdateTestGroupPending = _a.UpdateTestGroupPending, UpdateTestGroupSuccess = _a.UpdateTestGroupSuccess, UpdateTestGroupFailure = _a.UpdateTestGroupFailure, DeleteTestGroupPending = _a.DeleteTestGroupPending, DeleteTestGroupSuccess = _a.DeleteTestGroupSuccess, DeleteTestGroupFailure = _a.DeleteTestGroupFailure;
export var fetchTestGroups = function (params, page) {
    return apiCallBegan({
        url: "/admin/test-groups?page=".concat(page, "&search_key=").concat(params.search_key.trim(), "&start_date=").concat(params.start_date, "&end_date=").concat(params.end_date),
        method: 'GET',
        onStart: TestGroupListPending.type,
        onSuccess: TestGroupListSuccess.type,
        onError: TestGroupListFailure.type,
    });
};
export var fetchTestGroupById = function (id) {
    return apiCallBegan({
        url: "/admin/test-groups/".concat(id),
        method: 'GET',
        onStart: TestGroupPending.type,
        onSuccess: TestGroupSuccess.type,
        onError: TestGroupFailure.type,
    });
};
export var createTestGroup = function (data) {
    return apiCallBegan({
        url: '/admin/test-groups',
        method: 'POST',
        data: data,
        onStart: CreateTestGroupPending.type,
        onSuccess: CreateTestGroupSuccess.type,
        onError: CreateTestGroupFailure.type,
    });
};
export var updateTestGroup = function (data, id) {
    return apiCallBegan({
        url: "/admin/test-groups/".concat(id),
        method: 'PUT',
        data: data,
        onStart: UpdateTestGroupPending.type,
        onSuccess: UpdateTestGroupSuccess.type,
        onError: UpdateTestGroupFailure.type,
    });
};
export var deleteTestGroup = function (id) {
    return apiCallBegan({
        url: "/admin/test-groups/".concat(id),
        method: 'DELETE',
        onStart: DeleteTestGroupPending.type,
        onSuccess: DeleteTestGroupSuccess.type,
        onError: DeleteTestGroupFailure.type,
    });
};
