var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CustomButton from '../User_Roles/common/Button';
import { useMemo, useState } from 'react';
import QRListTable from './QRListTable';
import axiosInstance from '../../../../../utils/axios';
import { checkModulePrivilegeAccess, showToastMessage } from '../../../../../utils/helpers';
import Validator from 'validatorjs';
import { CircularProgress } from '@material-ui/core';
import QRCodeModal from './Form';
import { fetchQrsList } from '../../../../../features/settings/QRCodes/qrSlice';
import { useDispatch, useSelector } from 'react-redux';
import AlertDialog from './Modal';
var initialValues = {
    name: '',
    vendor_name: '',
    test_id: '',
};
var QRCode = function () {
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.qrcode; }), qrcodeList = _a.qrcodeList, isLoading = _a.isLoading;
    var _b = useState(initialValues), params = _b[0], setParams = _b[1];
    var _c = useState(initialValues), errors = _c[0], setErrors = _c[1];
    var _d = useState(''), autocompleteValue = _d[0], setAutocompleteValue = _d[1];
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    var _f = useState(), row = _f[0], setRow = _f[1];
    var _g = useState({
        create: false,
        warning: false,
        edit: false,
        toggle: false,
    }), open = _g[0], setOpen = _g[1];
    var handlePopup = function (key, value, row) {
        var _a;
        setOpen(__assign(__assign({}, open), (_a = {}, _a[key] = value, _a)));
        setRow(row);
    };
    var handleAutoComplete = function (extraParam) { return function (event, newValue) {
        setErrors('');
        setParams(__assign(__assign({}, params), { test_id: newValue.id }));
        setAutocompleteValue(newValue);
    }; };
    var handleDelete = function () {
        setLoading(true);
        axiosInstance
            .delete("/admin/vendor-qrs/".concat(row))
            .then(function (res) {
            showToastMessage('QR deleted successfully', 'success');
            setOpen(false);
            dispatch(fetchQrsList());
            setLoading(false);
        })
            .catch(function (err) {
            var _a = err.response.data, errors = _a.errors, message = _a.message;
            var erroMsg = errors[Object.keys(errors)[0]] || message;
            showToastMessage(erroMsg, 'error');
            setLoading(false);
        });
    };
    var handleClose = function () {
        setOpen(false);
        setParams(initialValues);
        setErrors(initialValues);
    };
    var handleChange = function (e) {
        var _a;
        setErrors('');
        var _b = e.target, name = _b.name, value = _b.value;
        setParams(__assign(__assign({}, params), (_a = {}, _a[name] = value, _a)));
    };
    var validate = function (parameters, rule) {
        var validator = new Validator(parameters, rule);
        if (validator.fails()) {
            var fieldErrors = {};
            /* eslint-disable */
            for (var key in validator.errors.errors) {
                fieldErrors[key] = validator.errors.errors[key][0];
            }
            /* eslint-enable */
            setErrors(fieldErrors);
            return false;
        }
        setErrors({});
        return true;
    };
    //create and update
    var CreateQR = function () {
        if (!validate(params, {
            name: 'required',
            vendor_name: 'required',
        })) {
            var err = Object.keys(errors);
            if (err === null || err === void 0 ? void 0 : err.length) {
                var input = document.querySelector("input[name=".concat(err[0], "]"));
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    });
                }
            }
            showToastMessage('Please check form errors!', 'error');
            return;
        }
        setLoading(true);
        axiosInstance
            .post("admin/vendor-qrs", params)
            .then(function (response) {
            showToastMessage('Vendor QR created successfully', 'success');
            setOpen(false);
            setLoading(false);
            dispatch(fetchQrsList());
            setParams(initialValues);
        })
            .catch(function (err) {
            setLoading(false);
            var _a = err.response.data, errors = _a.errors, message = _a.message;
            var erroMsg = errors[Object.keys(errors)[0]] || message;
            showToastMessage(erroMsg, 'error');
        });
    };
    useMemo(function () {
        dispatch(fetchQrsList());
    }, []);
    return (_jsxs("div", __assign({ className: 'w-full rounded flex flex-col gap-6' }, { children: [_jsxs("div", __assign({ className: 'default_container bg-white ' }, { children: [_jsxs("div", __assign({ className: 'flex justify-between item-center mb-2' }, { children: [_jsxs("div", { children: [_jsx("p", __assign({ className: 'w-24 sm:w-fit subheading' }, { children: "QR Code" })), _jsx("p", __assign({ className: 'text-sm' }, { children: "Add/ Remove Items to the List of QR Code." }))] }), checkModulePrivilegeAccess('settings', 'is_write') &&
                                _jsx(CustomButton, __assign({ onClick: function () {
                                        handlePopup('create', true, '');
                                    }, variant: 'outlined', size: 'large', borderRadius: '8px' }, { children: "+Add QR Code" }))] })), _jsx("div", __assign({ className: ' flex flex-col gap-4 justify-center ' }, { children: _jsx("div", { children: isLoading ? (_jsxs("div", __assign({ className: 'w-full h-80 flex justify-center items-center' }, { children: [_jsx(CircularProgress, {}), _jsx("span", __assign({ className: 'text-3xl' }, { children: "Loading..." }))] }))) : (_jsx(QRListTable, { dataList: qrcodeList, handlePopup: handlePopup })) }) })), _jsx("br", {})] })), _jsxs("div", __assign({ className: '' }, { children: [_jsx(QRCodeModal, { open: open === null || open === void 0 ? void 0 : open.create, handleClose: handleClose, label: 'Submit', params: params, errors: errors, handleAutoComplete: handleAutoComplete, value: autocompleteValue, handleChange: handleChange, onSubmit: CreateQR, isLoading: loading }), _jsx(AlertDialog, { label: 'Are your sure need to delete this Checklist?', popupmsg: 'Doing this will update the status!', handleClose: handleClose, status: open === null || open === void 0 ? void 0 : open.warning, handleSubmit: handleDelete, loading: loading })] }))] })));
};
export default QRCode;
