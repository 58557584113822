var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { checkModulePrivilegeAccess, uuid } from '../../../../../utils/helpers';
import { CircularProgress, Tooltip } from '@mui/material';
import Toggle from '../../../../Common/Input/Toggle';
var useStyles = makeStyles(function () { return ({
    root: {
        '& td ': {
            color: '#141C4C',
        },
        '& th ': {
            color: 'rgba(20, 28, 76, 0.7)',
        },
    },
    tr: {
        '& td:first-child ': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
        },
        '& td:last-child ': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
        },
    },
}); });
var CheckListTable = function (_a) {
    var _b;
    var dataList = _a.dataList, updateStatus = _a.updateStatus, isLoading = _a.isLoading, handlePopup = _a.handlePopup;
    var classes = useStyles();
    return (_jsx(_Fragment, { children: _jsx(TableContainer, __assign({ component: Paper, elevation: 0 }, { children: isLoading ? (_jsxs("div", __assign({ className: 'w-full h-96 flex justify-center items-center' }, { children: [_jsx(CircularProgress, {}), _jsx("span", __assign({ className: 'text-3xl' }, { children: "Loading..." }))] }))) : dataList ? (_jsxs(Table, __assign({ "aria-label": 'simple table', sx: (_b = {},
                    _b["& .".concat(tableCellClasses.root)] = {
                        borderBottom: '1px solid #E7E8ED',
                    },
                    _b.minWidth = 650,
                    //   border: '1px solid #E7E8ED',
                    _b.borderCollapse = 'separate',
                    _b.borderSpacing = '0px 5px',
                    _b.px = '24px',
                    _b.background = '#F1F2F8',
                    _b.borderRadius = '8px',
                    _b['& .css-zvlqj6-MuiTableCell-root'] = {
                        padding: 0,
                    },
                    _b.padding = 0,
                    _b), className: classes.root }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ align: 'center', sx: {
                                        color: '#5B6082',
                                        fontSize: '0.8rem',
                                        borderRight: '1px solid #E7E8ED',
                                    } }, { children: _jsx("span", { children: "Sl No." }) })), _jsx(TableCell, __assign({ align: 'center', sx: {
                                        color: '#5B6082',
                                        fontSize: '0.8rem',
                                        borderRight: '1px solid #E7E8ED',
                                    } }, { children: _jsx("span", { children: "Field Name" }) })), _jsx(TableCell, __assign({ align: 'center', sx: {
                                        color: '#5B6082',
                                        fontSize: '0.8rem',
                                        borderRight: '1px solid #E7E8ED',
                                    } }, { children: _jsx("span", { children: "Mandatory or Not" }) })), _jsx(TableCell, __assign({ align: 'center', sx: {
                                        color: '#5B6082',
                                        fontSize: '0.8rem',
                                        borderRight: '1px solid #E7E8ED',
                                    } }, { children: _jsx("span", { children: "Active/Inactive" }) })), _jsx(TableCell, __assign({ align: 'center', sx: { color: '#5B6082', fontSize: '0.8rem' } }, { children: _jsx("span", { children: "Action" }) }))] }) }), _jsx(TableBody, { children: dataList &&
                            (dataList === null || dataList === void 0 ? void 0 : dataList.map(function (row, index) {
                                var _a;
                                return (_jsxs(TableRow, __assign({ sx: { '&:last-child td, &:last-child th': { border: 0 } } }, { children: [_jsx(TableCell, __assign({ sx: { fontSize: '0.8rem', color: '#141C4C' }, align: 'center' }, { children: index + 1 })), _jsx(TableCell, __assign({ sx: { fontSize: '0.8rem', color: '#141C4C' }, align: 'center' }, { children: _jsx("div", __assign({ className: 'flex justify-center' }, { children: (_a = row.name) !== null && _a !== void 0 ? _a : '-' })) })), _jsx(TableCell, __assign({ sx: { fontSize: '0.8rem', color: '#141C4C' }, align: 'center' }, { children: row.is_mandatory ? 'Yes' : 'No' })), _jsx(TableCell, __assign({ align: 'center', sx: { fontSize: '0.8rem', color: '#141C4C' } }, { children: _jsx("span", { children: checkModulePrivilegeAccess('settings', 'is_update') ? (_jsx(Toggle, { name: '', ischecked: row === null || row === void 0 ? void 0 : row.is_active, handleCheck: function (e) {
                                                        handlePopup('toggle', true, row);
                                                    } })) : (_jsx(_Fragment, { children: "NA" })) }) })), _jsx(TableCell, __assign({ align: 'center', sx: { padding: '0px', fontSize: '0.8rem' } }, { children: _jsxs("div", __assign({ className: 'flex justify-center gap-2' }, { children: [checkModulePrivilegeAccess('settings', 'is_update') && (_jsx(Tooltip, __assign({ title: 'Edit Check List', onClick: function () {
                                                            handlePopup('edit', true, row);
                                                        } }, { children: _jsxs("svg", __assign({ width: '24', height: '24', className: 'cursor-pointer', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M13.748 20.4438H21.0006', stroke: '#0C8EC7', "stroke-width": '1.5', "stroke-linecap": 'round', "stroke-linejoin": 'round' }), _jsx("path", { "fill-rule": 'evenodd', "clip-rule": 'evenodd', d: 'M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z', stroke: '#0C8EC7', "stroke-width": '1.5', "stroke-linecap": 'round', "stroke-linejoin": 'round' }), _jsx("path", { d: 'M11.0215 6L16.4737 10.1871', stroke: '#0C8EC7', "stroke-width": '1.5', "stroke-linecap": 'round', "stroke-linejoin": 'round' })] })) }))), checkModulePrivilegeAccess('settings', 'is_delete') && (_jsx(Tooltip, __assign({ title: 'delete Checklist' }, { children: _jsxs("svg", __assign({ onClick: function () {
                                                                handlePopup('warning', true, row);
                                                            }, width: '24', height: '24', className: 'cursor-pointer', viewBox: '0 0 24 24', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M19.3238 9.46875C19.3238 9.46875 18.7808 16.2037 18.4658 19.0407C18.3158 20.3957 17.4788 21.1898 16.1078 21.2148C13.4988 21.2618 10.8868 21.2648 8.27881 21.2098C6.95981 21.1828 6.13681 20.3788 5.98981 19.0478C5.67281 16.1858 5.13281 9.46875 5.13281 9.46875', stroke: '#F14D4D', "stroke-width": '1.5', "stroke-linecap": 'round', "stroke-linejoin": 'round' }), _jsx("path", { d: 'M20.708 6.23828H3.75', stroke: '#F14D4D', "stroke-width": '1.5', "stroke-linecap": 'round', "stroke-linejoin": 'round' }), _jsx("path", { d: 'M17.4406 6.239C16.6556 6.239 15.9796 5.684 15.8256 4.915L15.5826 3.699C15.4326 3.138 14.9246 2.75 14.3456 2.75H10.1126C9.53358 2.75 9.02558 3.138 8.87558 3.699L8.63258 4.915C8.47858 5.684 7.80258 6.239 7.01758 6.239', stroke: '#F14D4D', "stroke-width": '1.5', "stroke-linecap": 'round', "stroke-linejoin": 'round' })] })) })))] })) }))] }), uuid()));
                            })) })] }))) : (_jsx("div", __assign({ className: 'rounded-lg w-full bg-darkGray p-8' }, { children: _jsx("p", __assign({ className: 'text-xl font-nunitoRegular text-center text-white ' }, { children: "No Users Found!" })) }))) })) }));
};
export default CheckListTable;
