var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { toast } from 'react-toastify';
var showToastMessage = function (message, type) {
    if (type === 'error') {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    else {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
};
var initialState = {
    labsList: [],
    deleteStatus: false,
    isLabsListLoading: false,
    metadata: {
        totalLabs: 0,
        totalPages: 0,
        total: 0,
    },
    isLoading: false,
    total_labs: 0,
};
export var labSlice = createSlice({
    name: 'hubs',
    initialState: initialState,
    reducers: {
        CreateLabPending: function (state, action) { },
        CreateLabSuccess: function (state, action) {
            var _a, _b, _c;
            console.log('lab record created', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage((_c = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message, 'success');
        },
        CreateLabFailure: function (state, action) {
            var _a, _b;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'error');
        },
        LabsListPending: function (state, action) {
            state.isLabsListLoading = true;
        },
        LabsListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            state.isLabsListLoading = false;
            state.deleteStatus = false;
            state.labsList = action.payload.data.data || [];
            state.metadata.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.metadata.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.metadata.totalLabs = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_labs;
        },
        LabsListFailure: function (state, action) {
            var _a, _b;
            state.isLabsListLoading = false;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'error');
        },
        UpdateLabPending: function (state, action) { },
        UpdateLabSuccess: function (state, action) {
            var _a, _b, _c;
            console.log('lab record updated', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage((_c = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message, 'success');
        },
        UpdateLabFailure: function (state, action) {
            var _a, _b;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'error');
        },
        DeletePending: function (state, action) { },
        DeleteSuccess: function (state, action) {
            var _a, _b, _c;
            // showToastMessage(action.payload.data.message, 'success')
            console.log('deleted job opening', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            state.deleteStatus = true;
            showToastMessage((_c = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message, 'success');
        },
        DeleteFailure: function (state, action) {
            var _a;
            state.deleteStatus = false;
            console.log('delete job opening by id', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage("You can't delete the role. Its associate with multiple applicants", 'error');
        },
    },
});
export var CreateLabPending = (_a = labSlice.actions, _a.CreateLabPending), CreateLabSuccess = _a.CreateLabSuccess, CreateLabFailure = _a.CreateLabFailure, LabsListPending = _a.LabsListPending, LabsListSuccess = _a.LabsListSuccess, LabsListFailure = _a.LabsListFailure, UpdateLabPending = _a.UpdateLabPending, UpdateLabSuccess = _a.UpdateLabSuccess, UpdateLabFailure = _a.UpdateLabFailure, DeletePending = _a.DeletePending, DeleteSuccess = _a.DeleteSuccess, DeleteFailure = _a.DeleteFailure;
export default labSlice.reducer;
//CREATE HUB
export var createLabRecord = function (data) {
    return apiCallBegan({
        url: '/admin/labs',
        method: 'POST',
        data: data,
        onStart: CreateLabPending.type,
        onSuccess: CreateLabSuccess.type,
        onError: CreateLabFailure.type,
    });
};
//LABS list
export var getLabsList = function (params, page) {
    return apiCallBegan({
        url: "/admin/labs?page=".concat(page, "&search_key=").concat(params.search_key),
        method: 'GET',
        onStart: LabsListPending.type,
        onSuccess: LabsListSuccess.type,
        onError: LabsListFailure.type,
    });
};
//update lab record
export var updateLabRecord = function (data, id) {
    return apiCallBegan({
        url: "/admin/labs/".concat(id),
        method: 'PUT',
        data: data,
        onStart: UpdateLabPending.type,
        onSuccess: UpdateLabSuccess.type,
        onError: UpdateLabFailure.type,
    });
};
export var deleteLabById = function (id) {
    return apiCallBegan({
        url: "/admin/labs/".concat(id),
        method: 'DELETE',
        onStart: DeletePending.type,
        onSuccess: DeleteSuccess.type,
        onError: DeleteFailure.type,
    });
};
