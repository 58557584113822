var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import RadioGroup, { useRadioGroup } from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
var StyledFormControlLabel = styled(function (props) { return (_jsx(FormControlLabel, __assign({}, props))); })(function (_a) {
    var checked = _a.checked;
    return ({
        '.MuiFormControlLabel-label': checked
            ? { color: '#0C8EC7' }
            : { color: '#000' },
    });
});
var MyFormControlLabel = function (props) {
    var radioGroup = useRadioGroup();
    var checked = false;
    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }
    return _jsx(StyledFormControlLabel, __assign({ checked: checked }, props));
};
var RadioButton = function (_a) {
    var items = _a.items, name = _a.name, onChange = _a.onChange, checked = _a.checked, defaultValue = _a.defaultValue, row = _a.row;
    return (_jsxs("div", __assign({ className: " w-full" }, { children: [' ', _jsx(RadioGroup, __assign({ name: name, defaultValue: defaultValue }, { children: _jsxs("div", __assign({ className: "".concat(row ? '' : 'flex flex-col') }, { children: [' ', items.map(function (e) { return (_jsx(MyFormControlLabel, { value: e === null || e === void 0 ? void 0 : e.value, label: e === null || e === void 0 ? void 0 : e.label, control: (_jsx(Radio, { checked: checked, onChange: onChange, sx: {
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 133, 255, 0.1) ',
                                    },
                                    // '&.Mui-checked': {
                                    //   color: '#141C4C',
                                    // },
                                } })) })); })] })) }))] })));
};
export default RadioButton;
