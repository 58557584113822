var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
var initialState = {
    userStatusDropdown: [],
    userRolesDropdown: [],
    status: true,
    rolesData: [],
    samplesDropdown: [],
    hubs: [],
    testsDropdown: [],
    testAndGroupsDropdown: [],
    leadStatusDropdown: [],
    usersDropdown: [],
    leadSourcesDropdown: [],
    ordersDropdown: [],
    userDropDown: [],
    orderUsers: [],
    coupons: [],
    orderStatusdropdown: '',
};
export var dropdownSlice = createSlice({
    name: 'dropdown',
    initialState: initialState,
    reducers: {
        StatusDropdownSuccess: function (state, action) { },
        StatusDropdownPending: function (state, action) { },
        StatusDropdownFailed: function (state, action) { },
        RoleDropdownSuccess: function (state, action) {
            state.userRolesDropdown = action.payload.data;
        },
        RoleDropdownPending: function (state, action) { },
        RoleDropdownFailed: function (state, action) { },
        DropDownPending: function (state, action) { },
        DropDownSuccess: function (state, action) {
            console.log('status & sources:', action === null || action === void 0 ? void 0 : action.payload.data);
            state.leadStatusDropdown = action.payload.data.status;
            state.leadSourcesDropdown = action.payload.data.source;
        },
        DropDownFailure: function (state, action) { },
        RolesPending: function (state, action) { },
        RolesSuccess: function (state, action) {
            console.log('roles dropdown:', action === null || action === void 0 ? void 0 : action.payload.data);
            state.rolesData = action.payload.data;
        },
        RolesFailure: function (state, action) { },
        SampleDropdownPending: function (state, action) { },
        SampleDropdownSuccess: function (state, action) {
            var _a, _b;
            console.log('samplesDropdown dropdown success', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            state.samplesDropdown = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data;
        },
        SampleDropdownFailure: function (state, action) { },
        TestsDropdownPending: function (state, action) { },
        TestsDropdownSuccess: function (state, action) {
            var _a, _b;
            console.log('tests dropdown success', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            state.testsDropdown = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data;
        },
        TestsDropdownFailure: function (state, action) { },
        testAndGroupsDropdownSuccess: function (state, action) {
            console.log('roles dropdown:', action === null || action === void 0 ? void 0 : action.payload.data);
            state.testAndGroupsDropdown = action.payload.data;
        },
        testAndGroupsDropdownFailure: function (state, action) { },
        hubsDropdownSuccess: function (state, action) {
            state.hubs = action.payload.data;
        },
        ExecutiveSuccess: function (state, action) {
            state.usersDropdown = action.payload.data;
        },
        ExecutiveFailure: function (state, action) { },
        LeadSourcesSuccess: function (state, action) {
            state.leadSourcesDropdown = action.payload.data.source;
        },
        LeadSourceFailure: function (state, action) { },
        ordersDropdownSuccess: function (state, action) {
            state.ordersDropdown = action.payload.data;
        },
        UserDropdownSuccess: function (state, action) {
            state.userDropDown = action.payload.data;
        },
        OrderUserDropdownSuccess: function (state, action) {
            state.orderUsers = action.payload.data;
        },
        CouponsDropdownSuccess: function (state, action) {
            state.coupons = action.payload.data;
        },
        OrderStatusDropdownSuccess: function (state, action) {
            var _a;
            state.orderStatusdropdown = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
        },
    },
});
export var LeadSourcesSuccess = (_a = dropdownSlice.actions, _a.LeadSourcesSuccess), LeadSourceFailure = _a.LeadSourceFailure, ExecutiveSuccess = _a.ExecutiveSuccess, ExecutiveFailure = _a.ExecutiveFailure, StatusDropdownSuccess = _a.StatusDropdownSuccess, StatusDropdownPending = _a.StatusDropdownPending, RoleDropdownSuccess = _a.RoleDropdownSuccess, RoleDropdownPending = _a.RoleDropdownPending, RoleDropdownFailed = _a.RoleDropdownFailed, StatusDropdownFailed = _a.StatusDropdownFailed, DropDownSuccess = _a.DropDownSuccess, DropDownPending = _a.DropDownPending, DropDownFailure = _a.DropDownFailure, RolesPending = _a.RolesPending, RolesSuccess = _a.RolesSuccess, RolesFailure = _a.RolesFailure, SampleDropdownPending = _a.SampleDropdownPending, SampleDropdownSuccess = _a.SampleDropdownSuccess, SampleDropdownFailure = _a.SampleDropdownFailure, TestsDropdownPending = _a.TestsDropdownPending, TestsDropdownSuccess = _a.TestsDropdownSuccess, TestsDropdownFailure = _a.TestsDropdownFailure, testAndGroupsDropdownSuccess = _a.testAndGroupsDropdownSuccess, testAndGroupsDropdownFailure = _a.testAndGroupsDropdownFailure, hubsDropdownSuccess = _a.hubsDropdownSuccess, ordersDropdownSuccess = _a.ordersDropdownSuccess, UserDropdownSuccess = _a.UserDropdownSuccess, OrderUserDropdownSuccess = _a.OrderUserDropdownSuccess, CouponsDropdownSuccess = _a.CouponsDropdownSuccess, OrderStatusDropdownSuccess = _a.OrderStatusDropdownSuccess;
export default dropdownSlice.reducer;
export var fetchUserStatusDropdown = function (data) {
    return apiCallBegan({
        url: '/admin/roles/dropdowns',
        method: 'GET',
        data: data,
        onStart: StatusDropdownPending.type,
        onSuccess: StatusDropdownSuccess.type,
        onError: StatusDropdownFailed.type,
    });
};
export var fetchUserRoleDropdown = function () {
    return apiCallBegan({
        url: '/admin/roles/dropdown',
        method: 'GET',
        onStart: RoleDropdownPending.type,
        onSuccess: RoleDropdownSuccess.type,
        onError: RoleDropdownFailed.type,
    });
};
export var getStatusSources = function () {
    return apiCallBegan({
        url: '/admin/leads/options/dropdown',
        method: 'GET',
        onStart: DropDownPending.type,
        onSuccess: DropDownSuccess.type,
        onError: DropDownFailure.type,
    });
};
export var fetchLeadSourcesDropdown = function () {
    return apiCallBegan({
        url: '/admin/leads/options/dropdown',
        method: 'GET',
        onSuccess: LeadSourcesSuccess.type,
        onError: LeadSourceFailure.type,
    });
};
export var getRoles = function (module) {
    return apiCallBegan({
        url: "/admin/users/dropdown?module=leads",
        method: 'GET',
        onStart: RolesPending.type,
        onSuccess: RolesSuccess.type,
        onError: RolesFailure.type,
    });
};
export var getUsersDropdown = function (module) {
    return apiCallBegan({
        url: "/admin/users/dropdown?module=".concat(module),
        method: 'GET',
        onSuccess: ExecutiveSuccess.type,
        onError: ExecutiveFailure.type,
    });
};
export var fetchSamplesDropdown = function () {
    return apiCallBegan({
        url: '/admin/samples/dropdown',
        method: 'GET',
        onSuccess: SampleDropdownSuccess.type,
        onError: SampleDropdownFailure.type,
    });
};
export var fetchTests = function () {
    return apiCallBegan({
        url: '/admin/tests/dropdown',
        method: 'GET',
        onStart: TestsDropdownPending.type,
        onSuccess: TestsDropdownSuccess.type,
        onError: TestsDropdownFailure.type,
    });
};
export var fetchTestAndGroupsDropdown = function () {
    return apiCallBegan({
        url: "/admin/tests-groups/dropdown",
        method: 'GET',
        onSuccess: testAndGroupsDropdownSuccess.type,
        onError: testAndGroupsDropdownFailure.type,
    });
};
export var fetchHubsDropdown = function () {
    return apiCallBegan({
        url: "/admin/hubs/dropdown",
        method: 'GET',
        onSuccess: hubsDropdownSuccess.type,
    });
};
export var fetchOrdersDropdown = function () {
    return apiCallBegan({
        url: "/admin/orders/dropdown",
        method: 'GET',
        onSuccess: ordersDropdownSuccess.type,
    });
};
export var fetchUserDropdown = function () {
    return apiCallBegan({
        url: "/admin/users/dropdown",
        method: 'GET',
        onSuccess: UserDropdownSuccess.type,
    });
};
export var fetchOrderUserDropdown = function () {
    return apiCallBegan({
        url: "/admin/users/dropdown?module=orders",
        method: 'GET',
        onSuccess: OrderUserDropdownSuccess.type,
    });
};
export var fetchCoupons = function (data) {
    return apiCallBegan({
        url: "/admin/coupons/dropdown",
        method: 'PATCH',
        data: data,
        onSuccess: CouponsDropdownSuccess.type,
    });
};
export var fetchOrdeStatusdropdown = function () {
    return apiCallBegan({
        url: "/admin/orders/prerequisite",
        method: 'GET',
        onSuccess: OrderStatusDropdownSuccess.type,
    });
};
