var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Tab from './Tab';
import CircularProgress from '@mui/material/CircularProgress';
import UserRoleInfo from './Tabs/User_Roles/UserRoleInfo';
import CheckList from './Tabs/CheckList/CheckList';
import QRCode from './Tabs/QRcodes/QRCodeList';
import SampleForm from './Tabs/SampleCollectionPrice/SampleForm';
var Settings = function () {
    var TabConstants = [
        {
            title: 'User Role',
        },
        {
            title: 'Checklist',
        },
        {
            title: 'Sample Collection Charges',
        },
        {
            title: 'QR Codes',
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: 'text-SpaceCadet font-nunitoBold text-xl' }, { children: "Admin Panel Settings" })), _jsx("br", {}), false ? (_jsxs("div", __assign({ className: 'w-full h-80 flex justify-center items-center' }, { children: [_jsx(CircularProgress, {}), _jsx("span", __assign({ className: 'text-3xl' }, { children: "Loading..." }))] }))) : (_jsx("div", { children: _jsx("div", __assign({ className: 'flex flex-col gap-5 ' }, { children: _jsx("div", __assign({ className: 'rounded-lg' }, { children: _jsx(Tab, { cols: TabConstants, data: [_jsx(UserRoleInfo, { custId: 1 }), _jsx(CheckList, {}), _jsx(SampleForm, {}), _jsx(QRCode, {})
                            ] }) })) })) }))] }));
};
export default Settings;
