var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { toast } from 'react-toastify';
var showToastMessage = function (message, type) {
    if (type === 'error') {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    else {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
};
var initialState = {
    ordersList: [],
    // deleteStatus: false,
    statList: {},
    isCreated: false,
    isLoading: false,
    modulesList: [],
    total_orders: 0,
    last_page: 0,
    total_constant_orders: 0,
};
export var orderSlice = createSlice({
    name: 'orders',
    initialState: initialState,
    reducers: {
        CreateOrderPending: function (state, action) { },
        CreateOrderSuccess: function (state, action) {
            var _a, _b;
            state.isCreated = true;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'success');
        },
        CreateOrderFailure: function (state, action) {
            var _a, _b;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'error');
        },
        OrderListPending: function (state, action) {
            state.isLoading = true;
        },
        OrderListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            state.isLoading = false;
            state.isCreated = false;
            // console.log('order list from slice', action.payload.total_orders)
            state.ordersList = action.payload.data.data || [];
            state.total_orders = (_c = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.meta) === null || _c === void 0 ? void 0 : _c.total;
            state.last_page = (_f = (_e = (_d = action === null || action === void 0 ? void 0 : action.payload) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.meta) === null || _f === void 0 ? void 0 : _f.last_page;
            state.total_constant_orders = action.payload.total_orders;
        },
        StatListSuccess: function (state, action) {
            state.isLoading = false;
            state.isCreated = false;
            state.statList = action.payload.data || {};
        },
        OrderListFailure: function (state, action) {
            var _a, _b;
            state.isLoading = false;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'error');
        },
    },
});
export var CreateOrderPending = (_a = orderSlice.actions, _a.CreateOrderPending), CreateOrderSuccess = _a.CreateOrderSuccess, CreateOrderFailure = _a.CreateOrderFailure, OrderListPending = _a.OrderListPending, OrderListSuccess = _a.OrderListSuccess, OrderListFailure = _a.OrderListFailure, StatListSuccess = _a.StatListSuccess;
export default orderSlice.reducer;
export var createOrder = function (data) {
    return apiCallBegan({
        url: '/admin/orders',
        method: 'POST',
        data: data,
        onStart: CreateOrderPending.type,
        onSuccess: CreateOrderSuccess.type,
        onError: CreateOrderFailure.type,
    });
};
// list of roles
export var getOrdersListing = function (params, page) {
    return apiCallBegan({
        url: "/admin/orders?page=".concat(page, "&search_key=").concat(params.search_key, "&start_date=").concat(params.start_date, "&end_date=").concat(params.end_date, "&payment_status=").concat(params.payment_status, "&status=").concat(params.status, "&assigned_to=").concat(params.assigned_to, "&sample_start_date=").concat(params.sample_start_date, "&sample_end_date=").concat(params.sample_end_date, "&limit=").concat(params.limit),
        method: 'GET',
        onStart: OrderListPending.type,
        onSuccess: OrderListSuccess.type,
        onError: OrderListFailure.type,
    });
};
export var getStatListing = function (date) {
    return apiCallBegan({
        url: "admin/orders/stats?start_date=".concat(date.start_date, "&end_date=").concat(date.end_date),
        method: 'GET',
        onSuccess: StatListSuccess.type,
    });
};
