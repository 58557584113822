import { jsx as _jsx } from "react/jsx-runtime";
// import * as React from 'react';
// import PDFViewer from 'pdf-viewer-reactjs'
// const PdfViewer = ({ item }:any) => {
//   return (
//     <div>
//         <PDFViewer
//             document={{
//                 url: item?.payment_link,
//             }}
//         />
//     </div>
//   );
// }
// export default PdfViewer
import React from 'react';
var PdfViewer = function () {
    return (_jsx("div", { children: "PdfViewer" }));
};
export default PdfViewer;
