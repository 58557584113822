var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
var NotFound = function () { return (_jsxs("div", __assign({ className: "h-screen justify-center text-center" }, { children: [_jsx("div", __assign({ className: "mt-24 m-auto" }, { children: _jsxs("div", __assign({ className: " tracking-widest mt-4" }, { children: [_jsx("span", __assign({ className: "text-gray-500 text-6xl block" }, { children: _jsx("span", { children: "4 0 4" }) })), _jsx("span", __assign({ className: "text-gray-500 text-xl" }, { children: "Sorry, We couldn't find what you are looking for!" }))] })) })), _jsx("div", __assign({ className: "mt-6" }, { children: _jsx(Link, __assign({ to: "/dashboard" }, { children: _jsxs("a", __assign({ className: "text-gray-500 font-mono text-xl bg-gray-200 p-3 rounded-md hover:shadow-md" }, { children: ["Go back", ' '] })) })) }))] }))); };
export default NotFound;
