var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
var label = { inputProps: { 'aria-label': 'Switch demo' } };
var GreenSwitch = styled(Switch)(function () { return ({
    '& .MuiSwitch-switchBase.Mui-disabled': {
        color: '#C5C9D6',
    },
    '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#FFFFFF',
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#0C8EC7',
        '&:hover': {
            backgroundColor: 'rgba(20, 28, 76,0.1)',
        },
    },
    ' & .MuiSwitch-switchBase': {
        color: '#141C4C',
        '&:hover': {
            backgroundColor: 'rgba(0, 133, 255, 0.1)',
        },
    },
    '.MuiSwitch-track': {
        backgroundColor: '#141C4C',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#0C8EC7',
    },
}); });
var Toggle = function (_a) {
    var ischecked = _a.ischecked, handleCheck = _a.handleCheck, defaultChecked = _a.defaultChecked, disabled = _a.disabled, name = _a.name;
    return (_jsx("div", { children: _jsx(GreenSwitch, __assign({ name: name, disabled: disabled, checked: ischecked, onChange: handleCheck }, label)) }));
};
export default Toggle;
