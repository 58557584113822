var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';
var label = { inputProps: { 'aria-label': 'Checkbox demo' } };
var useStyles = makeStyles(function () { return ({
    root: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'rgba(0, 133, 255, 0.1) !important',
        },
    },
}); });
var CustomCheckbox = function (_a) {
    var defaultChecked = _a.defaultChecked, handleCheck = _a.handleCheck, ischecked = _a.ischecked, Label = _a.Label, name = _a.name, color = _a.color;
    var classes = useStyles();
    return (_jsxs("div", __assign({ className: '' }, { children: [_jsx(Checkbox, __assign({ name: name, id: Label, checked: ischecked, className: classes.root }, label, { defaultChecked: defaultChecked, onChange: handleCheck, icon: _jsx("svg", __assign({ width: '20', height: '20', viewBox: '0 0 20 20', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("rect", { x: '0.75', y: '0.75', width: '18.5', height: '18.5', rx: '5.25', stroke: '#141C4C', "stroke-width": '1.5' }) })), checkedIcon: _jsx("svg", __assign({ width: '20', height: '20', viewBox: '0 0 20 20', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: _jsx("path", { "fill-rule": 'evenodd', "clip-rule": 'evenodd', d: 'M5.67 0H14.34C17.73 0 20 2.38 20 5.92V14.091C20 17.62 17.73 20 14.34 20H5.67C2.28 20 0 17.62 0 14.091V5.92C0 2.38 2.28 0 5.67 0ZM9.42945 12.99L14.1795 8.24C14.5195 7.9 14.5195 7.35 14.1795 7C13.8395 6.66 13.2795 6.66 12.9395 7L8.80945 11.13L7.05945 9.38C6.71945 9.04 6.15945 9.04 5.81945 9.38C5.47945 9.72 5.47945 10.27 5.81945 10.62L8.19945 12.99C8.36945 13.16 8.58945 13.24 8.80945 13.24C9.03945 13.24 9.25945 13.16 9.42945 12.99Z', fill: '#0C8EC7' }) })), sx: {
                    '& .MuiSvgIcon-root': {
                        fontSize: 70,
                        borderRadius: 20,
                    },
                } })), _jsx("label", __assign({ htmlFor: Label, className: " sm:ml-2 break-word sm:text-sm text-xs  cursor-pointer ".concat(ischecked ? 'text-yellow' : "".concat(color)) }, { children: Label }))] })));
};
CustomCheckbox.defaultProps = {
    defaultChecked: false,
    ischecked: false,
    Label: '',
};
export default CustomCheckbox;
