var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from './../../utils/helpers';
var initialState = {
    userList: [],
    isloading: false,
    usersData: {
        total_count: 0,
        total_active_users: 0,
        total_inactive_users: 0,
    },
    isUserListLoading: false,
    metadata: {
        totalUsers: 0,
        totalPages: 0,
        total: 0,
    },
    logsmeta: {
        totalLogs: 0,
        totalPages: 0,
        total: 0,
    },
    userById: {},
    userLogs: [],
};
export var userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        UserListSuccess: function (state, action) {
            // console.log('action:', action.payload)
            var _a, _b, _c, _d, _e, _f;
            state.isUserListLoading = false;
            state.userList = action.payload.data.data || [];
            state.metadata.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.metadata.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.metadata.totalUsers = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_users;
        },
        UserListPending: function (state, action) {
            state.isUserListLoading = true;
        },
        UserListFailed: function (state, action) {
            state.isUserListLoading = false;
        },
        UserFetchedPending: function (state, action) {
            console.log('action:', action);
            state.isloading = true;
        },
        UserFetchedSuccess: function (state, action) {
            var _a, _b;
            console.log('userById:', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            state.userById = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data;
            state.isloading = false;
        },
        UserFetchedFailed: function (state, action) {
            state.isloading = false;
        },
        UserLogsSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            console.log('fetchUserLogs');
            state.userLogs = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data;
            state.logsmeta.totalPages = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data.meta) === null || _d === void 0 ? void 0 : _d.last_page;
            state.logsmeta.total = (_g = (_f = (_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.meta) === null || _g === void 0 ? void 0 : _g.total;
            state.logsmeta.totalLogs = (_k = (_j = (_h = action === null || action === void 0 ? void 0 : action.payload) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.meta) === null || _k === void 0 ? void 0 : _k.total;
        },
        UserLogsPending: function (state, action) { },
        UserLogsFailed: function (state, action) { },
        UserStatusSuccess: function (state, action) {
            console.log('action:', action.payload);
            showToastMessage(action.payload.data.message, 'success');
        },
        UserStatusFailed: function (state, action) {
            showToastMessage(action.payload.message, 'error');
        },
        // User Analytics
        UsersDataSuccess: function (state, action) {
            state.usersData = action.payload.data;
        },
        UsersDataPending: function (state, action) { },
        UsersDataFailed: function (state, action) {
            console.log('action:', action.payload);
        },
    },
});
export var UserListSuccess = (_a = userSlice.actions, _a.UserListSuccess), UserListPending = _a.UserListPending, UserListFailed = _a.UserListFailed, UserFetchedPending = _a.UserFetchedPending, UserFetchedSuccess = _a.UserFetchedSuccess, UserFetchedFailed = _a.UserFetchedFailed, UserLogsSuccess = _a.UserLogsSuccess, UserLogsFailed = _a.UserLogsFailed, UserLogsPending = _a.UserLogsPending, UserStatusSuccess = _a.UserStatusSuccess, UserStatusFailed = _a.UserStatusFailed, UsersDataSuccess = _a.UsersDataSuccess, UsersDataPending = _a.UsersDataPending, UsersDataFailed = _a.UsersDataFailed;
export default userSlice.reducer;
export var fetchUsers = function (data, page) {
    return apiCallBegan({
        url: "/admin/users?status=".concat(data.status, "&search_key=").concat(data.search_key, "&role_id=").concat(data.role_id, "&page=").concat(page),
        method: 'GET',
        data: data,
        onStart: UserListPending.type,
        onSuccess: UserListSuccess.type,
        onError: UserListFailed.type,
    });
};
export var fetchUserById = function (id) {
    return apiCallBegan({
        url: "/admin/users/".concat(id),
        method: 'GET',
        onStart: UserFetchedPending.type,
        onSuccess: UserFetchedSuccess.type,
        onError: UserFetchedFailed.type,
    });
};
export var fetchUserLogs = function (data) {
    return apiCallBegan({
        url: "/admin/users/logs/".concat(data.id, "?page=").concat(data.page, "&start_date=").concat(data.start_date, "&end_date=").concat(data.end_date),
        method: 'GET',
        onStart: UserLogsPending.type,
        onSuccess: UserLogsSuccess.type,
        onError: UserLogsFailed.type,
    });
};
export var UpdateUserStatus = function (id, data) {
    return apiCallBegan({
        url: "/admin/users/update-status/".concat(id),
        method: 'PATCH',
        data: data,
        onSuccess: UserStatusSuccess.type,
        onError: UserStatusFailed.type,
    });
};
// Users Analytics
export var fetchUsersAnalytics = function (start_date, end_date) {
    return apiCallBegan({
        url: "/admin/users/stats?start_date=".concat(start_date, "&end_date=").concat(end_date),
        method: 'GET',
        onSuccess: UsersDataSuccess.type,
        onStart: UsersDataPending.type,
        onError: UsersDataFailed.type,
    });
};
