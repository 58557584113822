var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import * as user from '../../utils/auth';
import { showToastMessage } from './../../utils/helpers';
var initialState = {
    user: {},
    isLoading: false,
    apiSuccess: false,
    access_token: '',
    errors: {
        login: {},
        forgotPassword: {},
        resetPassword: {},
        updatePassword: '',
    },
};
export var loginSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        formReset: function (state) {
            state.errors.login = {};
        },
        resetLoading: function (state) {
            state.isLoading = true;
        },
        resetapiSuccess: function (state) {
            state.apiSuccess = false;
        },
        loginSuccessful: function (state, action) {
            var _a, _b, _c;
            console.log('action:', action.payload);
            state.isLoading = false;
            if (action.payload.status && !((_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.new_user)) {
                localStorage.setItem('auth_user', JSON.stringify((_c = action.payload) === null || _c === void 0 ? void 0 : _c.data));
                localStorage.setItem('user-token', action.payload.data.token.token);
                state.user = action.payload.data;
            }
            else {
                console.log(action.payload);
                state.access_token = action.payload.data.token.token;
            }
        },
        authenticationSuccessful: function (state, action) {
            if (action.payload.email) {
                state.user = {
                    email: action.payload.email,
                };
            }
        },
        loginFailure: function (state, action) {
            console.log('action:', action.payload);
            state.errors.login = action.payload;
            state.isLoading = false;
            showToastMessage(action.payload.message, 'error');
        },
        forgotPasswordSuccessful: function (state, action) {
            if (action.payload.status) {
                state.isLoading = false;
                state.apiSuccess = true;
            }
        },
        forgotPasswordFailure: function (state, action) {
            state.errors.forgotPassword = action.payload;
            state.isLoading = false;
            showToastMessage(action.payload.message, 'error');
        },
        resetPasswordSuccessful: function (state, action) {
            if (action.payload.status) {
                state.isLoading = false;
                state.apiSuccess = true;
                showToastMessage(action.payload.message, 'success');
            }
        },
        resetPasswordFailure: function (state, action) {
            state.errors.forgotPassword = action.payload;
            state.isLoading = false;
            showToastMessage(action.payload.message, 'error');
        },
        loggedOut: function (state) {
            state.user = {};
            user.logout();
        },
        clearAccessToken: function (state) {
            state.access_token = '';
        },
        updatedPasswordSuccessful: function (state, action) {
            console.log(action, 'action');
            if (action.payload.status) {
                state.user = action.payload.data;
                state.isLoading = false;
                localStorage.setItem('auth_user', JSON.stringify(state.user));
                state.access_token = '';
                user.login(action.payload.data.token.token);
            }
        },
        updatePasswordFailure: function (state, action) {
            state.errors.updatePassword = action.payload;
            state.isLoading = false;
            showToastMessage(action.payload.message, 'error');
        },
    },
});
export var loginSuccessful = (_a = loginSlice.actions, _a.loginSuccessful), loginFailure = _a.loginFailure, authenticationSuccessful = _a.authenticationSuccessful, loggedOut = _a.loggedOut, formReset = _a.formReset, resetLoading = _a.resetLoading, forgotPasswordFailure = _a.forgotPasswordFailure, forgotPasswordSuccessful = _a.forgotPasswordSuccessful, resetPasswordFailure = _a.resetPasswordFailure, resetPasswordSuccessful = _a.resetPasswordSuccessful, updatedPasswordSuccessful = _a.updatedPasswordSuccessful, updatePasswordFailure = _a.updatePasswordFailure, clearAccessToken = _a.clearAccessToken, resetapiSuccess = _a.resetapiSuccess;
export default loginSlice.reducer;
export var login = function (data) {
    return apiCallBegan({
        url: '/login',
        method: 'POST',
        data: data,
        onStart: resetLoading.type,
        onSuccess: loginSuccessful.type,
        onError: loginFailure.type,
    });
};
export var forgotPassword = function (data) {
    return apiCallBegan({
        url: '/forgot-password',
        method: 'POST',
        data: data,
        onStart: resetLoading.type,
        onSuccess: forgotPasswordSuccessful.type,
        onError: forgotPasswordFailure.type,
    });
};
export var resetPassword = function (data) {
    return apiCallBegan({
        url: '/reset-password',
        method: 'POST',
        data: data,
        onStart: resetLoading.type,
        onSuccess: resetPasswordSuccessful.type,
        onError: resetPasswordFailure.type,
    });
};
export var updatePassword = function (data) {
    return apiCallBegan({
        url: '/update-password',
        method: 'POST',
        data: data,
        onStart: resetLoading.type,
        onSuccess: updatedPasswordSuccessful.type,
        onError: updatePasswordFailure.type,
    });
};
export var authenticate = function () {
    return apiCallBegan({
        url: '/authenticate',
        method: 'GET',
        onSuccess: authenticationSuccessful.type,
        onError: loggedOut.type,
    });
};
export var logout = function () {
    return apiCallBegan({
        url: '/logout',
        method: 'POST',
        onSuccess: loggedOut.type,
    });
};
