var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Button from '@mui/material/Button';
import React from 'react';
var styles = {
    border: '1px solid #141C4C',
    color: '#141C4C',
    '&:hover': {
        border: '1px solid #141C4C',
        backgroundColor: '#fff',
    },
};
var styles1 = {
    border: 'none',
    color: '#fff',
    fontWeight: '700',
    // background: 'linear-gradient(180deg, #FFD756 0%,#FFCD2C 100%)',
    backgroundColor: '#0C8EC7',
    '&:hover': {
        border: 'none',
        background: '#4FBAE9',
        backgroundColor: '#4FBAE9',
    },
    '&:active': {
        background: '#8EDDFF',
    },
};
var disabledStyles = {
    '&:disabled': {
        fontWeight: '700',
        border: 'none ',
        color: '#797979',
        backgroundColor: '#D9D9D9',
    },
};
var CustomButton = function (_a) {
    var disabled = _a.disabled, children = _a.children, onClick = _a.onClick, _b = _a.variant, variant = _b === void 0 ? 'outlined' : _b, width = _a.width, size = _a.size, icon = _a.icon, borderRadius = _a.borderRadius, _c = _a.type, type = _c === void 0 ? 'button' : _c;
    return (_jsx("div", __assign({ className: width }, { children: _jsx(Button, __assign({ style: { borderRadius: borderRadius, textTransform: 'none' }, fullWidth: true, size: size, disabled: disabled, onClick: onClick, variant: variant, startIcon: icon, className: "font-nunitoRegular", type: type, sx: variant === 'outlined' && !disabled
                ? styles
                : variant === 'contained' && !disabled
                    ? styles1
                    : disabled && variant === 'contained'
                        ? {
                            '&:disabled': {
                                fontWeight: '700',
                                border: 'none',
                                color: '#6A6A78 !important',
                                backgroundColor: '#2F3344',
                            },
                        }
                        : disabled
                            ? disabledStyles
                            : '' }, { children: _jsx("p", __assign({ className: "w-full font-bold font-nunitoRegular text-sm" }, { children: children })) })) })));
};
CustomButton.defaultProps = {
    disabled: false,
    children: null,
    variant: '',
    width: '',
    size: '',
    icon: '',
    borderRadius: '',
    onClick: function test() { },
};
export default CustomButton;
