var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
var TextArea = function (_a) {
    var placeholder = _a.placeholder, id = _a.id, name = _a.name, rows = _a.rows, className = _a.className, value = _a.value, error = _a.error, handleChange = _a.handleChange, helperText = _a.helperText, readOnly = _a.readOnly;
    return (_jsxs("div", __assign({ className: 'relative' }, { children: [_jsx("textarea", { readOnly: readOnly, className: "w-full border border-border  ".concat(className ? className : 'bg-transparent', " rounded-lg  text-SpaceCadet p-4 placeholder-placeholder  placeholder:text-Comet focus:border-WaterBlue  ").concat(className, "  ").concat(error && 'border-GlowingBrakeDisc placeholder:text-GlowingBrakeDisc'), name: name, id: id, rows: rows, value: value, onChange: handleChange, placeholder: placeholder }), helperText && (_jsx("p", __assign({ className: 'absolute -bottom-3 ml-4 text-GlowingBrakeDisc text-xs' }, { children: helperText })))] })));
};
export default TextArea;
TextArea.defaultProps = {
    placeholder: '',
    id: '',
    name: '',
    className: '',
    value: '',
    error: false,
    handleChange: function test() { },
    helperText: '',
};
