var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CustomButton from '../User_Roles/common/Button';
import { useMemo, useState } from 'react';
import CheckListTable from './CheckListTable';
import CheckListModal from './Form';
import axiosInstance from '../../../../../utils/axios';
import { checkModulePrivilegeAccess, showToastMessage } from '../../../../../utils/helpers';
import Validator from 'validatorjs';
import { CircularProgress } from '@material-ui/core';
import AlertDialog from './Modal';
var CheckList = function () {
    var initialValues = {
        name: '',
        is_mandatory: '',
    };
    var _a = useState(initialValues), params = _a[0], setParams = _a[1];
    var _b = useState(initialValues), errors = _b[0], setErrors = _b[1];
    var _c = useState(), checkList = _c[0], setCheckList = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var _e = useState(), row = _e[0], setRow = _e[1];
    var _f = useState({
        create: false,
        warning: false,
        edit: false,
        toggle: false,
    }), open = _f[0], setOpen = _f[1];
    var handlePopup = function (key, value, row) {
        var _a;
        setOpen(__assign(__assign({}, open), (_a = {}, _a[key] = value, _a)));
        setRow(row);
        if (key === 'status') {
            setParams(row);
            return;
        }
        if (key !== 'create') {
            var mandatory = 'Yes';
            (row === null || row === void 0 ? void 0 : row.is_mandatory) ? (mandatory = 'Yes') : (mandatory = 'No');
            setParams(__assign(__assign({}, params), { name: row === null || row === void 0 ? void 0 : row.name, is_mandatory: mandatory, id: row === null || row === void 0 ? void 0 : row.id }));
        }
    };
    //delete Checklist
    var handleDelete = function () {
        setLoading(true);
        axiosInstance
            .delete("/admin/check-list/".concat(params === null || params === void 0 ? void 0 : params.id))
            .then(function (res) {
            showToastMessage(res.data.data.message, 'success');
            setOpen(false);
            fetchCheckList();
            setLoading(false);
            setParams(initialValues);
        })
            .catch(function (err) {
            var _a = err.response.data, errors = _a.errors, message = _a.message;
            var erroMsg = errors[Object.keys(errors)[0]] || message;
            showToastMessage(erroMsg, 'error');
            setLoading(false);
        });
    };
    var handleClose = function () {
        setOpen(false);
        setParams(initialValues);
        setErrors(initialValues);
    };
    var handleChange = function (e) {
        var _a;
        setErrors('');
        var _b = e.target, name = _b.name, value = _b.value;
        setParams(__assign(__assign({}, params), (_a = {}, _a[name] = value, _a)));
    };
    //fetchCheckList
    var fetchCheckList = function () {
        setLoading(true);
        axiosInstance
            .get("/admin/check-list")
            .then(function (res) {
            setCheckList(res.data.data);
            setLoading(false);
        })
            .catch(function (err) {
            var _a = err.response.data, errors = _a.errors, message = _a.message;
            var erroMsg = errors[Object.keys(errors)[0]] || message;
            showToastMessage(erroMsg, 'error');
            setLoading(false);
        });
    };
    var validate = function (parameters, rule) {
        var validator = new Validator(parameters, rule);
        if (validator.fails()) {
            var fieldErrors = {};
            /* eslint-disable */
            for (var key in validator.errors.errors) {
                fieldErrors[key] = validator.errors.errors[key][0];
            }
            /* eslint-enable */
            setErrors(fieldErrors);
            return false;
        }
        setErrors({});
        return true;
    };
    //create and update
    var createCheckList = function () {
        var rules = {
            name: 'required',
            is_mandatory: 'required',
        };
        if (!validate(params, rules)) {
            var err = Object.keys(errors);
            if (err === null || err === void 0 ? void 0 : err.length) {
                var input = document.querySelector("input[name=".concat(err[0], "]"));
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    });
                }
            }
            showToastMessage('Please check form errors!', 'error');
            return;
        }
        setLoading(true);
        var payload = {
            name: params.name,
            is_mandatory: params.is_mandatory === 'Yes' ? true : false,
        };
        if (params === null || params === void 0 ? void 0 : params.id)
            axiosInstance
                .put("/admin/check-list/".concat(params === null || params === void 0 ? void 0 : params.id), payload)
                .then(function (response) {
                showToastMessage(response.data.data.message, 'success');
                setOpen(false);
                setLoading(false);
                fetchCheckList();
                setParams(initialValues);
            })
                .catch(function (err) {
                setLoading(false);
                var _a = err.response.data, errors = _a.errors, message = _a.message;
                var erroMsg = errors[Object.keys(errors)[0]] || message;
                showToastMessage(erroMsg, 'error');
            });
        else
            axiosInstance
                .post("/admin/check-list", payload)
                .then(function (response) {
                showToastMessage(response.data.data.message, 'success');
                setOpen(false);
                setLoading(false);
                fetchCheckList();
                setParams(initialValues);
            })
                .catch(function (err) {
                setLoading(false);
                var _a = err.response.data, errors = _a.errors, message = _a.message;
                var erroMsg = errors[Object.keys(errors)[0]] || message;
                showToastMessage(erroMsg, 'error');
            });
    };
    //updatesttaus
    var updateStatus = function () {
        setLoading(true);
        axiosInstance
            .get("admin/check-list/update-status/".concat(params === null || params === void 0 ? void 0 : params.id))
            .then(function (res) {
            showToastMessage(res.data.data.message, 'success');
            fetchCheckList();
            setOpen(false);
            setLoading(false);
            setParams(initialValues);
        })
            .catch(function (err) {
            setLoading(false);
            var _a = err.response.data, errors = _a.errors, message = _a.message;
            var erroMsg = errors[Object.keys(errors)[0]] || message;
            showToastMessage(erroMsg, 'error');
        });
    };
    useMemo(function () {
        fetchCheckList();
    }, []);
    return (_jsxs("div", __assign({ className: 'w-full rounded flex flex-col gap-6' }, { children: [_jsxs("div", __assign({ className: 'default_container bg-white ' }, { children: [_jsxs("div", __assign({ className: 'flex justify-between item-center mb-2' }, { children: [_jsxs("div", { children: [_jsx("p", __assign({ className: 'w-24 sm:w-fit subheading' }, { children: "Checklist" })), _jsx("p", __assign({ className: 'text-sm' }, { children: "Add/ Remove Items to the List of Checklist." }))] }), checkModulePrivilegeAccess('settings', 'is_write') && (_jsx(CustomButton, __assign({ onClick: function () {
                                    handlePopup('create', true, '');
                                }, variant: 'outlined', size: 'large', borderRadius: '8px' }, { children: "+Add Checklist" })))] })), _jsx("div", __assign({ className: ' flex flex-col gap-4  justify-center ' }, { children: _jsx("div", { children: loading ? (_jsxs("div", __assign({ className: 'w-full h-80 flex justify-center items-center' }, { children: [_jsx(CircularProgress, {}), _jsx("span", __assign({ className: 'text-3xl' }, { children: "Loading..." }))] }))) : (_jsx(CheckListTable, { dataList: checkList, handlePopup: handlePopup })) }) })), _jsx("br", {})] })), _jsxs("div", __assign({ className: '' }, { children: [_jsx(CheckListModal, { open: open === null || open === void 0 ? void 0 : open.create, handleClose: handleClose, label: 'Submit', type: 'create', params: params, errors: errors, handleChange: handleChange, onSubmit: createCheckList, isLoading: loading }), _jsx(CheckListModal, { open: open === null || open === void 0 ? void 0 : open.edit, handleClose: handleClose, label: 'Update', type: 'update', params: params, errors: errors, handleChange: handleChange, onSubmit: createCheckList, isLoading: loading }), _jsx(AlertDialog, { label: "Are you sure need to ".concat((row === null || row === void 0 ? void 0 : row.is_active) ? 'disable' : 'enable', " this?"), popupmsg: 'Doing this will update the status!', handleClose: handleClose, status: open === null || open === void 0 ? void 0 : open.toggle, handleSubmit: updateStatus, loading: loading }), _jsx(AlertDialog, { label: 'Are your sure need to delete this Checklist?', popupmsg: 'Doing this will update the status!', handleClose: handleClose, status: open === null || open === void 0 ? void 0 : open.warning, handleSubmit: handleDelete, loading: loading })] }))] })));
};
export default CheckList;
