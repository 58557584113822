var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from './../../utils/helpers';
var initialState = {
    leadsData: {
        intrested_leads: 0,
        no_response: 0,
        not_intrested_leads: 0,
        total_leads: 0,
        total_callbacks: 0
    },
    leadList: [],
    isLeadListLoading: false,
    isgetById: false,
    metadata: {
        totalLeads: 0,
        totalPages: 0,
        total: 0,
    },
    leadById: {},
    updateStatus: false,
    currentLogs: [],
};
export var leadSlice = createSlice({
    name: 'lead',
    initialState: initialState,
    reducers: {
        LeadListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            state.isLeadListLoading = false;
            state.leadList = action.payload.data.data;
            state.metadata.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.metadata.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.metadata.totalLeads = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_count;
        },
        LeadListPending: function (state, action) {
            state.isLeadListLoading = true;
        },
        LeadListFailed: function (state, action) {
            state.isLeadListLoading = false;
        },
        LeadFetchedPending: function (state, action) {
            state.isgetById = true;
        },
        LeadFetchedSuccess: function (state, action) {
            var _a, _b, _c;
            state.leadById = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            state.currentLogs = (_c = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.logs;
            state.isgetById = false;
        },
        LeadFetchedFailed: function (state, action) {
            state.isgetById = false;
        },
        LeadsFilterSuccess: function (state, action) {
            state.leadList = action.payload.data.data;
            // state.leadById = action?.payload?.data
        },
        LeadsFilterPending: function (state, action) { },
        LeadsFilterFailure: function (state, action) { },
        CreateLeadPending: function (state, action) { },
        CreateLeadSuccess: function (state, action) {
            showToastMessage(action.payload.data.message, 'success');
        },
        CreateLeadFailure: function (state, action) { },
        UpdateStatusPending: function (state, action) { },
        UpdateStatusSuccess: function (state, action) {
            state.updateStatus = true;
            showToastMessage(action.payload.data.message, 'success');
        },
        UpdateStatusFailure: function (state, action) {
            showToastMessage(action.payload.data.message, 'error');
        },
        StatusSuccess: function (state, action) {
            state.updateStatus = true;
            showToastMessage(action.payload.data.message, 'success');
        },
        StatusPending: function (state, action) { },
        StatusFailed: function (state, action) {
            showToastMessage(action.payload.data.message, 'error');
        },
        AssignStatusPending: function (state, action) { },
        AssignStatusSuccess: function (state, action) {
            state.updateStatus = true;
            showToastMessage(action.payload.data.message, 'success');
        },
        AssignStatusFailure: function (state, action) {
            showToastMessage(action.payload.data.message, 'error');
        },
        ReassignStatusPending: function (state, action) { },
        ReassignStatusSuccess: function (state, action) {
            state.updateStatus = true;
            showToastMessage(action.payload.data.message, 'success');
        },
        ReassignStatusFailure: function (state, action) {
            showToastMessage(action.payload.data.message, 'error');
        },
        AnalyticsSuccess: function (state, action) {
            console.log('action:', action.payload.data);
            // intrested_leads: 5
            // no_response: 0
            // not_intrested_leads: 2
            // total_leads: 23
            var leadStats = action.payload.data;
            state.leadsData.no_response = leadStats.no_response;
            state.leadsData.intrested_leads = leadStats.intrested_leads;
            state.leadsData.not_intrested_leads = leadStats.not_intrested_leads;
            state.leadsData.total_leads = leadStats.total_leads;
            state.leadsData.total_callbacks = leadStats.total_callbacks;
            // leadsData: {
            //   total_count: 0,
            //   total_active_leads: 0,
            //   total_inactive_leads: 0,
            // },
        },
        AnalyticsFailure: function (state, action) { },
    },
});
export var LeadListSuccess = (_a = leadSlice.actions, _a.LeadListSuccess), LeadListPending = _a.LeadListPending, LeadListFailed = _a.LeadListFailed, LeadFetchedPending = _a.LeadFetchedPending, LeadFetchedSuccess = _a.LeadFetchedSuccess, LeadFetchedFailed = _a.LeadFetchedFailed, CreateLeadSuccess = _a.CreateLeadSuccess, CreateLeadPending = _a.CreateLeadPending, CreateLeadFailure = _a.CreateLeadFailure, UpdateStatusSuccess = _a.UpdateStatusSuccess, UpdateStatusPending = _a.UpdateStatusPending, UpdateStatusFailure = _a.UpdateStatusFailure, StatusPending = _a.StatusPending, StatusSuccess = _a.StatusSuccess, StatusFailed = _a.StatusFailed, LeadsFilterSuccess = _a.LeadsFilterSuccess, LeadsFilterPending = _a.LeadsFilterPending, LeadsFilterFailure = _a.LeadsFilterFailure, AssignStatusPending = _a.AssignStatusPending, AssignStatusSuccess = _a.AssignStatusSuccess, AssignStatusFailure = _a.AssignStatusFailure, ReassignStatusPending = _a.ReassignStatusPending, ReassignStatusSuccess = _a.ReassignStatusSuccess, ReassignStatusFailure = _a.ReassignStatusFailure, AnalyticsSuccess = _a.AnalyticsSuccess, AnalyticsFailure = _a.AnalyticsFailure;
export default leadSlice.reducer;
export var leadCreation = function (data) {
    return apiCallBegan({
        url: '/admin/leads',
        method: 'POST',
        data: data,
        onStart: CreateLeadPending.type,
        onSuccess: CreateLeadSuccess.type,
        onError: CreateLeadFailure.type,
    });
};
export var fetchLeadById = function (id) {
    return apiCallBegan({
        url: "/admin/leads/".concat(id),
        method: 'GET',
        onStart: LeadFetchedPending.type,
        onSuccess: LeadFetchedSuccess.type,
        onError: LeadFetchedFailed.type,
    });
};
export var fetchLeads = function (params, page) {
    if (page === void 0) { page = 1; }
    return apiCallBegan({
        url: "/admin/leads?page=".concat(page, "&status=").concat(params.status, "&source=").concat(params.source, "&assigned_to=").concat(params.assigned_to, "&search_key=").concat(params.search_key.trim(), "&start_date=").concat(params.start_date, "&end_date=").concat(params.end_date, "&limit=").concat(params.limit),
        method: 'GET',
        onStart: LeadListPending.type,
        onSuccess: LeadListSuccess.type,
        onError: LeadListFailed.type,
    });
};
export var fetchLeadsList = function (page, params) {
    return apiCallBegan({
        url: "/admin/leads?page=".concat(page, "&source=").concat(params.source, "&start_date=").concat(params.start_date, "&end_date=").concat(params.end_date, "&assigned_to=").concat(params.salesExecutive, "&status=").concat(params.status, "&search_key=").concat(params.searchText),
        method: 'GET',
        onStart: LeadsFilterPending.type,
        onSuccess: LeadsFilterSuccess.type,
        onError: LeadsFilterFailure.type,
    });
};
export var leadStatusUpdation = function (data, id) {
    apiCallBegan({
        url: "/admin/lead/update-status/".concat(id),
        method: 'PATCH',
        data: data,
        onStart: UpdateStatusPending.type,
        onSuccess: UpdateStatusSuccess.type,
        onError: UpdateStatusFailure.type,
    });
};
export var leadUpdation = function (data, id) {
    return apiCallBegan({
        url: "/admin/leads/".concat(id),
        method: 'PUT',
        data: data,
        onStart: UpdateStatusPending.type,
        onSuccess: UpdateStatusSuccess.type,
        onError: UpdateStatusFailure.type,
    });
};
export var UpdateLeadStatus = function (data, id) {
    return apiCallBegan({
        url: "/admin/leads/update-status/".concat(id),
        method: 'PATCH',
        data: data,
        onStart: StatusPending.type,
        onSuccess: StatusSuccess.type,
        onError: StatusFailed.type,
    });
};
export var AssignLead = function (data, id) {
    return apiCallBegan({
        url: "/admin/leads/assign-lead/".concat(id),
        method: 'PATCH',
        data: data,
        onStart: AssignStatusPending.type,
        onSuccess: AssignStatusSuccess.type,
        onError: AssignStatusFailure.type,
    });
};
export var ReassignLead = function (data, id) {
    return apiCallBegan({
        url: "/admin/leads/reassign-lead/".concat(id),
        method: 'PATCH',
        data: data,
        onStart: ReassignStatusPending.type,
        onSuccess: ReassignStatusSuccess.type,
        onError: ReassignStatusFailure.type,
    });
};
export var fetchLeadAnalytics = function (data) {
    return apiCallBegan({
        url: "/admin/leads/stats?start_date=".concat(data.start_date, "&end_date=").concat(data.end_date),
        method: 'GET',
        // onStart: ReassignStatusPending.type,
        onSuccess: AnalyticsSuccess.type,
        onError: AnalyticsFailure.type,
    });
};
