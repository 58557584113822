var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { render } from 'react-dom';
var THEME = createTheme({
    typography: {
        fontFamily: [
            'Nunito Regular',
            'Nunito Medium',
            'Nunito Bold',
            'Nunito SemiBold',
            'Nunito Light',
        ].join(','),
    },
});
var root = ReactDOM.createRoot(document.getElementById('root'));
render(_jsx(ThemeProvider, __assign({ theme: THEME }, { children: _jsx(React.StrictMode, { children: _jsx(BrowserRouter, { children: _jsx(App, {}) }) }) })), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
