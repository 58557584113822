var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
function LineChart(_a) {
    var orders = _a.orders, orderPlaced = _a.orderPlaced, orderCompleted = _a.orderCompleted, orderCancelled = _a.orderCancelled, labels = _a.labels;
    var _b = useState({ series: [], chart: { toolbar: { show: false } }, colors: ['rgba(255, 196, 83, 0.2)', 'rgba(109, 196, 234, 0.2)', 'rgba(241, 77, 77, 0.2)', 'rgba(255,255,255, 0.0)'], }), chartOptions = _b[0], setChartOptions = _b[1];
    var setChartValue = function () {
        setChartOptions({
            chart: {
                type: 'line',
            },
            legend: {
                show: false
            },
            series: [
                {
                    name: 'Order Placed',
                    data: orderPlaced,
                    fill: {
                        colors: ['rgba(255, 196, 83, 0.5)']
                    }
                },
                {
                    name: 'Order Completed',
                    data: orderCompleted,
                },
                {
                    name: 'Order Cancelled',
                    data: orderCancelled,
                },
            ],
            xaxis: {
                categories: labels,
            },
            stroke: {
                curve: "straight",
                colors: ['rgba(255, 196, 83, 1)', 'rgba(109, 196, 234, 1)', 'rgba(241, 77, 77, 1)', 'transparent'],
                width: 2,
                background: ['#000000']
            },
            tooltip: {
                theme: 'dark',
                custom: function (_a) {
                    var _b;
                    var series = _a.series, seriesIndex = _a.seriesIndex, dataPointIndex = _a.dataPointIndex, w = _a.w;
                    console.log(orders, dataPointIndex);
                    return ('<div class="arrow_box"  >' +
                        "<span style='font-size:12px;color:white;'>" +
                        labels[dataPointIndex] +
                        "</span>" + "<br/>" +
                        "<span style='font-size:15px;color:white;'>" +
                        "Order Placed" +
                        ": " +
                        orderPlaced[dataPointIndex] +
                        "</span>" + "<br/>" +
                        "<span style=''>" + "<span style='font-size:15px;color:white;'>" +
                        "Order Completed" +
                        ": " +
                        "<span style='color:#44E377;'>" +
                        orderCompleted[dataPointIndex] + "</span>" +
                        "</span>" + "</span>" + "<br/>" +
                        "<span style='font-size:15px;color:white;'>" +
                        " Order Cancelled" +
                        ": " +
                        "<span style='color:#F14D4D;' >" +
                        orderCancelled[dataPointIndex] + "</span>" +
                        "</span>" + "<br/>" +
                        "<span style='font-size:15px;color:white;'>" +
                        " Revenue" +
                        ": AED " +
                        ((_b = orders === null || orders === void 0 ? void 0 : orders.totalOrders[dataPointIndex]) === null || _b === void 0 ? void 0 : _b.value) || 0 +
                        "</span>" +
                        "</div>");
                }
            },
            crosshairs: {
                show: false,
            },
            grid: {
                strokeDashArray: 5,
            },
            markers: {
                colors: ['rgba(255, 196, 83, 1)', 'rgba(109, 196, 234, 1)', 'rgba(241, 77, 77, 1)', 'transparent'],
                strokeWidth: 2,
                show: false
            },
            dataLabels: { enabled: false },
        });
    };
    useEffect(function () {
        setChartValue();
    }, [orders, orderPlaced, orderCancelled, orderCompleted, labels]);
    return (_jsx("div", __assign({ style: { userSelect: 'none' } }, { children: _jsx(ReactApexChart, { options: chartOptions, series: chartOptions.series, type: "area", height: 'auto' }) })));
}
export default LineChart;
