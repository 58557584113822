var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
var Card = function (_a) {
    var text = _a.text, value = _a.value, style = _a.style, loading = _a.loading, previousValue = _a.previousValue, currentValue = _a.currentValue;
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "".concat(style, " bg-white  p-6 flex justify-between lg:gap-20  items-center  border border-DreamyCloud") }, { children: [_jsxs("div", __assign({ className: 'flex flex-col space-y-1' }, { children: [_jsx("p", __assign({ className: 'text-Comet text-sm' }, { children: text })), _jsx("p", __assign({ className: 'text-lg font-nunitoBold' }, { children: loading ? _jsx("p", __assign({ className: 'font-nunitoBold' }, { children: "loading.." })) : _jsxs(_Fragment, { children: [" ", value || 0] }) }))] })), _jsx("div", __assign({ className: 'flex flex-col mt-6' }, { children: text === 'Total Number of Customer' ? (_jsx("p", { className: 'text-transparent w-16' })) : (_jsxs("span", __assign({ className: "".concat(previousValue > currentValue
                            ? 'bg-red-100 text-[#F14D4D]'
                            : 'bg-green-100 text-[#38AE5E]', " rounded-[18px]  gap-2 justify-center items-center px-2 py-1 hidden") }, { children: [_jsxs("p", __assign({ className: 'text-[14px] ' }, { children: [previousValue != 0 && currentValue ? (_jsx(_Fragment, { children: ((Number(currentValue - previousValue) / previousValue) * 100).toFixed(2) })) : ('0'), "%"] })), _jsx("span", __assign({ className: 'w-[16px] h-[16px]' }, { children: previousValue > currentValue ? (_jsxs("svg", __assign({ width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M8.1849 13.1641L8.1849 3.16406', stroke: '#F14D4D', "stroke-width": '1.5', "stroke-linecap": 'round', "stroke-linejoin": 'round' }), _jsx("path", { d: 'M12.2006 9.13281L8.18464 13.1661L4.16797 9.13281', stroke: '#F14D4D', "stroke-width": '1.5', "stroke-linecap": 'round', "stroke-linejoin": 'round' })] }))) : (_jsxs("svg", __assign({ width: '16', height: '16', viewBox: '0 0 16 16', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' }, { children: [_jsx("path", { d: 'M7.8151 2.83594L7.8151 12.8359', stroke: '#38AE5E', "stroke-width": '1.5', "stroke-linecap": 'round', "stroke-linejoin": 'round' }), _jsx("path", { d: 'M3.79936 6.86719L7.81536 2.83385L11.832 6.86719', stroke: '#38AE5E', "stroke-width": '1.5', "stroke-linecap": 'round', "stroke-linejoin": 'round' })] }))) }))] }))) }))] })) }));
};
export default Card;
