var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import deleteIcon from '../../../../../assets/icons/ListingIcons/deleteIcon.svg';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { checkModulePrivilegeAccess, uuid } from '../../../../../utils/helpers';
import { CircularProgress, Tooltip } from '@mui/material';
import Popup from '../../../../Common/Popup';
import editIcon from '../../../../../assets/icons/ListingIcons/editIcon.svg';
import { deleteRoleById } from '../../../../../features/settings/settingSlice';
var useStyles = makeStyles(function () { return ({
    root: {
        '& td ': {
            color: '#141C4C',
        },
        '& th ': {
            color: 'rgba(20, 28, 76, 0.7)',
        },
    },
    tr: {
        '& td:first-child ': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
        },
        '& td:last-child ': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
        },
    },
}); });
var UserRoleTable = function (_a) {
    var _b;
    var dataList = _a.dataList, onRowEdit = _a.onRowEdit;
    var dispatch = useDispatch();
    var classes = useStyles();
    // popup states
    var _c = useState({
        success: false,
        warning: false,
        question: false,
    }), open = _c[0], setOpen = _c[1];
    // Popup Handeling Functions
    var handlePopup = function (key, value) {
        var _a;
        setOpen(__assign(__assign({}, open), (_a = {}, _a[key] = value, _a)));
    };
    var _d = useState(), delID = _d[0], setDelID = _d[1];
    var handleYes = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            handlePopup('warning', false);
            dispatch(deleteRoleById(delID));
            return [2 /*return*/];
        });
    }); };
    var handleNo = function () {
        handlePopup('warning', false);
    };
    // const EditMember = (id: any) => {
    //     handleMemberPopup(true, 'edit')
    // }
    var deleteUser = function (id) {
        handlePopup('warning', true);
        setDelID(id);
    };
    var isLoading = useSelector(function (state) { return state.settings.isLoading; });
    return (_jsx(_Fragment, { children: _jsxs(TableContainer, __assign({ component: Paper, elevation: 0 }, { children: [isLoading ? (_jsxs("div", __assign({ className: 'w-full h-96 flex justify-center items-center' }, { children: [_jsx(CircularProgress, {}), _jsx("span", __assign({ className: 'text-3xl' }, { children: "Loading..." }))] }))) : dataList.length > 0 ? (_jsxs(Table, __assign({ "aria-label": 'simple table', sx: (_b = {},
                        _b["& .".concat(tableCellClasses.root)] = {
                            borderBottom: '1px solid #E7E8ED',
                        },
                        _b.minWidth = 650,
                        //   border: '1px solid #E7E8ED',
                        _b.borderCollapse = 'separate',
                        _b.borderSpacing = '0px 5px',
                        _b.px = '24px',
                        _b.background = '#F1F4F8',
                        _b.borderRadius = '8px',
                        _b['& .css-zvlqj6-MuiTableCell-root'] = {
                            padding: 0,
                        },
                        _b.padding = 0,
                        _b), className: classes.root }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, __assign({ align: 'center', sx: { color: '#5B6082', fontSize: '0.8rem' } }, { children: _jsx("span", { children: "Sl No." }) })), _jsx(TableCell, __assign({ align: 'center', sx: { color: '#5B6082', fontSize: '0.8rem' } }, { children: _jsx("span", { children: "User Role" }) })), _jsx(TableCell, __assign({ align: 'center', sx: { color: '#5B6082', fontSize: '0.8rem' } }, { children: _jsx("span", { children: "Title" }) })), _jsx(TableCell, __assign({ align: 'center', sx: { color: '#5B6082', fontSize: '0.8rem' } }, { children: _jsx("span", { children: "No of Modules" }) })), _jsx(TableCell, __assign({ align: 'center', sx: { color: '#5B6082', fontSize: '0.8rem' } }, { children: _jsx("span", { children: "Action" }) }))] }) }), _jsx(TableBody, { children: dataList.length > 0 &&
                                (dataList === null || dataList === void 0 ? void 0 : dataList.map(function (row) {
                                    var _a, _b, _c;
                                    return (_jsxs(TableRow, __assign({ sx: { '&:last-child td, &:last-child th': { border: 0 } } }, { children: [_jsx(TableCell, __assign({ sx: { fontSize: '0.8rem', color: '#141C4C' }, align: 'center' }, { children: (_a = row.id) !== null && _a !== void 0 ? _a : '-' })), _jsx(TableCell, __assign({ sx: { fontSize: '0.8rem', color: '#141C4C' }, align: 'center' }, { children: _jsx("div", __assign({ className: 'flex justify-center' }, { children: (_b = row.name) !== null && _b !== void 0 ? _b : '-' })) })), _jsx(TableCell, __assign({ sx: { fontSize: '0.8rem', color: '#141C4C' }, align: 'center' }, { children: row.is_manager ? 'Manager' : 'Executive' })), _jsx(TableCell, __assign({ sx: { fontSize: '0.8rem', color: '#141C4C' }, align: 'center' }, { children: (_c = row.permissions.length) !== null && _c !== void 0 ? _c : '-' })), _jsx(TableCell, __assign({ align: 'center', sx: { padding: '0px', fontSize: '0.8rem' } }, { children: _jsxs("div", __assign({ className: 'flex justify-center gap-2' }, { children: [checkModulePrivilegeAccess('settings', 'is_update') &&
                                                            _jsx(Tooltip, __assign({ title: 'Edit User role', onClick: function () {
                                                                    onRowEdit(row);
                                                                } }, { children: _jsx("img", { className: 'cursor-pointer', src: editIcon, alt: 'editIcon' }) })), checkModulePrivilegeAccess('settings', 'is_delete') && !row.is_default &&
                                                            _jsx(Tooltip, __assign({ title: 'delete role' }, { children: _jsx("img", { className: 'cursor-pointer', onClick: function () {
                                                                        deleteUser(row.id);
                                                                    }, src: deleteIcon, alt: 'deleteIcon' }) }))] })) }))] }), uuid()));
                                })) })] }))) : (_jsx("div", __assign({ className: 'rounded-lg w-full bg-darkGray p-8' }, { children: _jsx("p", __assign({ className: 'text-xl font-nunitoRegular text-center text-white ' }, { children: "No Users Found!" })) }))), _jsx("div", __assign({ className: '' }, { children: _jsx(Popup, { handleYes: handleYes, handleNo: handleNo, open: open.warning, handlePopup: handlePopup, popup: 'warning', isdeletebtn: true, subtitle: 'Are your sure need to delete this User?', popupmsg: 'Doing this will completely delete information and that cannot be retained agian!' }) }))] })) }));
};
export default UserRoleTable;
