import axios from 'axios';
import { logout } from './auth';
var baseURL = process.env.REACT_APP_BACKEND_URL;
var userToken = localStorage.getItem('user-token') || '';
var axiosInstance = axios.create({
    baseURL: baseURL,
    withCredentials: true,
});
if (userToken.length) {
    /**
     * we'll be setting user token on all request only
     * if we user token on local storage.
     */
    axiosInstance.defaults.headers.common.Authorization = "Bearer ".concat(localStorage.getItem('user-token'));
}
axiosInstance.defaults.withCredentials = true;
axiosInstance.interceptors.request.use(function (config) {
    if (config) {
        config.headers['Authorization'] = "Bearer ".concat(localStorage.getItem('user-token'));
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
axiosInstance.interceptors.response.use(function (res) {
    return res;
}, function (error) {
    if (error.response.status === 401) {
        logout();
        window.location.href = '/';
    }
    return Promise.reject(error);
});
export default axiosInstance;
