var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { uuid } from '../../../utils/helpers';
var useStyles = makeStyles({
    tabs: {
        backgroundColor: 'white',
        borderRadius: '8px',
        '& .MuiTabs-indicator': {
            backgroundColor: '#0C8EC7',
        },
        '& .MuiTabs-flexContainer button': {
            width: window.innerWidth < 768 ? '8rem' : '13rem !important',
        },
        '& .MuiTab-root.Mui-selected': {
            color: '#0C8EC7',
            fontFamily: 'Nunito !important',
            fontWeight: '700',
            fontSize: '18px',
            lineHeight: '20px',
        },
        '& .MuiButtonBase-root': { textTransform: 'none' },
    },
});
export var TabPanel = function (props) {
    var children = props.children, value = props.value, index = props.index, other = __rest(props, ["children", "value", "index"]);
    return (_jsx("div", __assign({ role: "tabpanel", hidden: value !== index, id: "simple-tabpanel-".concat(index), "aria-labelledby": "simple-tab-".concat(index) }, other, { children: value === index && (_jsx(Box, __assign({ sx: { pt: '20px' } }, { children: _jsx(Typography, { children: children }) }))) })));
};
function a11yProps(index) {
    return {
        id: "simple-tab-".concat(index),
        'aria-controls': "simple-tabpanel-".concat(index),
    };
}
var BasicTabs = function (_a) {
    var cols = _a.cols, data = _a.data, bg = _a.bg;
    var _b = React.useState(0), value = _b[0], setValue = _b[1];
    var handleChange = function (event, newValue) {
        setValue(newValue);
    };
    var px = '20px';
    if (window.innerWidth < 1024) {
        px = '6px';
    }
    var classes = useStyles();
    return (_jsxs(Box, __assign({ sx: { width: '100%' }, className: "w-full h-full bg-".concat(bg) }, { children: [_jsx(Box, { children: _jsx("div", { children: _jsx(Tabs, __assign({ variant: "scrollable", scrollButtons: true, allowScrollButtonsMobile: true, value: value, onChange: handleChange, className: classes.tabs, sx: { border: 1, borderColor: 'rgba(47, 72, 110, 0.1)' } }, { children: cols.map(function (col, index) { return (_jsx(Tab, __assign({ label: _jsx("span", __assign({ className: "font-nunitoRegular font-bold text-sm" }, { children: col.title })), sx: {
                                color: '#141C4C',
                                fontSize: '16px',
                                marginRight: "".concat(px),
                                lineHeight: '25px',
                            } }, a11yProps(index)), uuid())); }) })) }) }), data.map(function (item, index) { return (_jsx(TabPanel, __assign({ value: value, index: index }, { children: item }), uuid())); })] })));
};
export default BasicTabs;
