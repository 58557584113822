var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import Toggle from '../../../Common/Input/Toggle';
import { Tooltip } from '@mui/material';
import deleteIcon from '../../../../assets/icons/ListingIcons/deleteIcon.svg';
import editIcon from '../../../../assets/icons/ListingIcons/editIcon.svg';
import { checkModulePrivilegeAccess } from '../../../../utils/helpers';
var width = window.innerWidth;
var useStyles = makeStyles(function () { return ({
    root: {
        '& td ': {
            color: '#141C4C',
        },
    },
    tr: {
        '& td:first-child ': {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
        },
        '& td:last-child ': {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
        },
    },
}); });
var FeedBackTable = function (_a) {
    var _b;
    var cols = _a.cols, data = _a.data, handleToggleChange = _a.handleToggleChange, handleMemberPopup = _a.handleMemberPopup, deleteFeedback = _a.deleteFeedback;
    var classes = useStyles();
    return (_jsx(TableContainer, __assign({ elevation: 0, component: Paper, sx: { borderRadius: '0.5rem', backgroundColor: 'white', alignItems: 'center' } }, { children: _jsxs(Table, __assign({ sx: (_b = {},
                _b["& .".concat(tableCellClasses.root)] = {
                    borderBottom: 'none',
                },
                _b.minWidth = 650,
                _b.border = '1px solid #E7E8ED',
                _b.borderCollapse = 'separate',
                _b.borderSpacing = width < 768 ? '0px 3px' : '0px 20px',
                _b.px = '24px',
                _b.borderRadius = '8px',
                _b['& .css-zvlqj6-MuiTableCell-root'] = {
                    padding: 0,
                },
                _b['& .MuiTableCell-head'] = {
                    padding: 0,
                },
                _b), className: classes.root, "aria-label": 'simple table' }, { children: [_jsx(TableHead, { children: _jsx(TableRow, { children: cols.map(function (header) { return (_jsx(TableCell, __assign({ align: 'center', sx: { color: '#5B6082', fontSize: '0.8rem' } }, { children: header.title }))); }) }) }), _jsx(TableBody, { children: data === null || data === void 0 ? void 0 : data.map(function (item, index) { return (_jsxs(TableRow, __assign({ sx: { height: '16px', backgroundColor: '#F1F4F8', color: '#141C4C' }, className: classes.tr }, { children: [_jsx(TableCell, __assign({ align: 'center' }, { children: index + 1 })), _jsx(TableCell, __assign({ align: 'center', sx: { fontSize: '0.8rem', color: '#141C4C' } }, { children: moment(item.created_at).format('DD/MM/YYYY') })), _jsx(TableCell, __assign({ align: 'center', sx: { fontSize: '0.8rem', color: '#141C4C' } }, { children: (item === null || item === void 0 ? void 0 : item.name) || '--' })), _jsx(TableCell, __assign({ align: 'center', sx: { fontSize: '0.8rem', color: '#141C4C' } }, { children: (item === null || item === void 0 ? void 0 : item.phone) || '--' })), _jsx(TableCell, __assign({ align: 'center', sx: { fontSize: '0.8rem', color: '#141C4C' } }, { children: item.email || '--' })), _jsx(TableCell, __assign({ align: 'center', sx: { fontSize: '0.8rem', color: '#141C4C' } }, { children: _jsx("span", { children: checkModulePrivilegeAccess('feedback', 'is_update') ? (_jsx(Toggle, { name: '', ischecked: item === null || item === void 0 ? void 0 : item.is_approved, handleCheck: function (e) {
                                            handleToggleChange(e, item);
                                        } })) : (_jsx(_Fragment, { children: "NA" })) }) })), _jsx(TableCell, __assign({ align: 'center', sx: { padding: '0px', fontSize: '0.8rem' } }, { children: _jsxs("div", __assign({ className: 'flex justify-center gap-2' }, { children: [checkModulePrivilegeAccess('feedback', 'is_update') && (_jsx("div", __assign({ onClick: function () { return handleMemberPopup(true, 'edit', item); } }, { children: _jsx(Tooltip, __assign({ title: 'Edit hub' }, { children: _jsx("img", { className: 'mx-3 cursor-pointer', src: editIcon, alt: 'editIcon' }) })) }))), checkModulePrivilegeAccess('feedback', 'is_delete') && (_jsx(Tooltip, __assign({ title: 'delete job' }, { children: _jsx("img", { className: 'cursor-pointer', onClick: function () {
                                                    deleteFeedback(item);
                                                }, src: deleteIcon, alt: 'deleteIcon' }) })))] })) }))] }))); }) })] })) })));
};
export default FeedBackTable;
