var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
export var useStyles = makeStyles({
    error: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
            borderRadius: '8px',
        },
    },
    select: {
        '& ul': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        '& li': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    },
    icon: {
        fill: 'white',
    },
    root: {
        '& .MuiOutlinedInput-input': {
            color: '#141C4C',
        },
        '& .MuiInputLabel-root': {
            color: '#6A6A78',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E7E8ED',
            borderRadius: '8px',
        },
        '&:hover .MuiOutlinedInput-input': {
            color: '#141C4C',
        },
        '&:hover .MuiInputLabel-root': {
            color: '#6A6A78',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#141C4C',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#141C4C',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#0C8EC7',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0C8EC7',
        },
    },
});
export var SelectInput = function (_a) {
    var handleChange = _a.handleChange, value = _a.value, label = _a.label, error = _a.error, helperText = _a.helperText, options = _a.options, width = _a.width, name = _a.name, required = _a.required, readonly = _a.readonly, disabled = _a.disabled, fallback_message = _a.fallback_message, bgcolor = _a.bgcolor, id = _a.id;
    var classes = useStyles();
    return (_jsx("div", __assign({ className: 'w-full' }, { children: _jsxs(FormControl, __assign({ className: !error ? classes.root : classes.error, fullWidth: true, error: error, disabled: disabled }, { children: [_jsx(InputLabel, __assign({ id: id ? id : 'label-id' }, { children: label })), _jsx(Select, __assign({ labelId: 'select-input-label', style: {
                        borderRadius: '8px',
                        width: width,
                        backgroundColor: bgcolor || 'transparent',
                    }, 
                    // IconComponent={KeyboardArrowDownIcon}
                    MenuProps: {
                        sx: {
                            '&& .MuiMenuItem-root': {
                                backgroundColor: '#F5FBFD',
                                border: '1px solid #E7E8ED !important',
                                color: '#141C4C',
                                '&:hover': {
                                    backgroundColor: '#F5FBFD !important',
                                },
                            },
                            '&& .MuiMenu-list': {
                                padding: '0',
                            },
                            '&& .Mui-selected': {
                                color: '#0C8EC7 !important',
                                backgroundColor: '#F5FBFD',
                            },
                        },
                    }, sx: {
                        color: '#141C4C',
                        '.MuiSvgIcon-root ': {
                            fill: '#141C4C !important',
                        },
                    }, required: required, value: value, onChange: handleChange, label: label, name: name, error: error, fullWidth: true, readOnly: readonly }, { children: options.length > 0 ? (options === null || options === void 0 ? void 0 : options.map(function (item) { return (_jsxs(MenuItem, __assign({ value: item.id ? item.id : item.name || item }, { children: [(item === null || item === void 0 ? void 0 : item.name) ||
                                (item === null || item === void 0 ? void 0 : item.address_1) ||
                                (item === null || item === void 0 ? void 0 : item.code) ||
                                (item === null || item === void 0 ? void 0 : item.account_name) ||
                                (item === null || item === void 0 ? void 0 : item.company_name) ||
                                (item === null || item === void 0 ? void 0 : item.id) ||
                                (item === null || item === void 0 ? void 0 : item.category) ||
                                item, item.start &&
                                "-".concat(item === null || item === void 0 ? void 0 : item.start, " to\n              ").concat(item === null || item === void 0 ? void 0 : item.end)] }))); })) : (_jsx("p", __assign({ className: 'text-SpaceCadet p-4 text-xl' }, { children: fallback_message ? fallback_message : 'Options Not Found!' }))) })), _jsx(FormHelperText, { children: helperText })] })) })));
};
