var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
var useStyles = makeStyles({
    error: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
            borderRadius: '8px',
        },
    },
    select: {
        '& ul': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
        '& li': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    },
    icon: {
        fill: 'white',
    },
    root: {
        '& .MuiOutlinedInput-input': {
            color: '#141C4C',
        },
        '& .MuiInputLabel-root': {
            color: '#6A6A78',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E7E8ED',
            borderRadius: '8px',
        },
        '&:hover .MuiOutlinedInput-input': {
            color: '#141C4C',
        },
        '&:hover .MuiInputLabel-root': {
            color: '#6A6A78',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#141C4C',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
            color: '#141C4C',
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#0C8EC7',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0C8EC7',
        },
    },
});
export default function MyAutocomplete(_a) {
    var options = _a.options, value = _a.value, handleNewChange = _a.handleNewChange, label = _a.label, error = _a.error, helperText = _a.helperText, name = _a.name, readOnly = _a.readOnly;
    var classes = useStyles();
    return (_jsx(Autocomplete, { readOnly: readOnly, className: !error ? classes.root : classes.error, id: label, value: value === '' ? '' : value, options: options, onChange: handleNewChange(name), getOptionLabel: function (option) { return (option ? option.name || option : ''); }, fullWidth: true, style: { width: '100% !important' }, renderInput: function (params) { return (_jsx(TextField, __assign({ sx: { background: '#fcfcfc', borderRadius: '8px' } }, params, { name: label, label: label, error: error, variant: 'outlined', helperText: helperText, style: { backgroundColor: 'pink !important' } }))); } })
    // <Autocomplete
    //   className={!error ? classes.root : classes.error}
    //   value={value === '' ? '' : value}
    //   onChange={handleNewChange(name)}
    //   options={options}
    //   getOptionLabel={(option) => (option ? option.name || option : '')}
    //   renderInput={(params) => (
    //     <TextField
    //       sx={{ backgroundColor: 'white !important' }}
    //       error={error}
    //       helperText={<span className='text-red-600'>{helperText}</span>}
    //       {...params}
    //       name={label}
    //       label={label}
    //     />
    //   )}
    // />
    );
}
