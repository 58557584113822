import * as _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getLoggedInUser } from './auth';
/* eslint-disable */
export var uuid = function () {
    var uuid = '';
    var i;
    for (i = 0; i < 32; i += 1) {
        switch (i) {
            case 8:
            case 20:
                uuid += '-';
                uuid += (Math.random() * 16 || 0).toString(16);
                break;
            case 12:
                uuid += '-';
                uuid += '4';
                break;
            case 16:
                uuid += '-';
                uuid += (Math.random() * 4 || 8).toString(16);
                break;
            default:
                uuid += (Math.random() * 16 || 0).toString(16);
        }
    }
    return uuid;
};
/* eslint-enable */
export var capitalize = function (string) {
    if (!string) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export var capitalizeUppertoLower = function (string) {
    if (!string) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
};
export var getValueAsFloatWithNDecimalPlaces = function (value, decimalPlaces) {
    if (['0', 0].includes(value)) {
        return value;
    }
    return Number(value).toFixed(decimalPlaces);
};
export var validateArray = function (array) {
    if (!array || !Array.isArray(array) || !array.length) {
        return [];
    }
    return array;
};
export var copyToClipboard = function (value) {
    navigator.clipboard.writeText(_.unescape(value));
};
export var showToastMessage = function (message, type) {
    if (type === 'error') {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    else {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
};
export var defaultFiltersDropDown = [
    {
        id: 'ALL',
        name: 'All',
    },
    {
        id: '0',
        name: 'Today',
    },
    {
        id: '-1',
        name: 'Yesterday',
    },
    {
        id: '-7',
        name: 'Last 7 Days',
    },
    {
        id: '-30',
        name: 'Last 30 Days',
    },
    {
        id: 'TM',
        name: 'This Month',
    },
    {
        id: 'LM',
        name: 'Last Month',
    },
    {
        id: 'custom',
        name: 'Custom Date',
    },
];
export var dummyOptions = [
    { name: 'Facebook' },
    { name: 'Instagram' },
    { name: 'Linkedin' },
    { name: 'Farukh bhai' },
    { name: 'Raw Agent from SCUBE' },
    { name: 'Dubai' },
];
export var dateRange = function (type) {
    var range = [];
    switch (type) {
        case '0':
            range = [
                moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            ];
            break;
        case '-1':
            range = [
                moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            ];
            break;
        case '-7':
            range = [
                moment().subtract(6, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            ];
            break;
        case '-30':
            range = [
                moment().subtract(29, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            ];
            break;
        case 'TM':
            range = [
                moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                moment().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
            ];
            break;
        case 'LM':
            range = [
                moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
                moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
            ];
            break;
        default:
            range = [];
    }
    return range;
};
/* eslint-disable */
export var CountItems = function (data) {
    var total = 0;
    Object.values(data).forEach(function (val) {
        if (val === null || val === undefined || val === '') {
            return true;
        }
        total += 1;
    });
    // console.log(total, 'tpta;')
    return total;
};
/* eslint-enable */
export var isDate = function (params) {
    for (var key in params) {
        if (params[key].toString().includes('-'))
            return true;
        else
            return false;
    }
};
// get Age by dob
export var getAge = function (dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};
export var calculateAge = function (dob) {
    var birthDate = new Date(dob);
    var difference = Date.now() - birthDate.getTime();
    var age = new Date(difference);
    return Math.round(age.getUTCFullYear() - 1970);
};
export var validateNumber = function (phone) {
    if (!phone)
        return true;
    var phoneNumber = !String(phone).startsWith('+') ? "+".concat(phone) : phone;
    var regex = /^(\+971|0)?5[024568]\d{7}$/;
    return regex.test(phoneNumber);
};
export var locationTypes = [
    {
        id: 'HOME',
        value: 'HOME',
    },
    {
        id: 'OFFICE',
        value: 'OFFICE',
    },
    {
        id: 'OTHER',
        value: 'OTHER',
    },
];
export var cityList = [
    {
        id: 'Dubai',
        value: 'Dubai',
    },
    {
        id: 'Sharjha',
        value: 'Sharjha',
    },
    {
        id: 'Ajman',
        value: 'Ajman',
    }
];
export var getUserBaseModulePath = function (user) {
    if (!user)
        return '/login';
    var path = '/';
    var dashboardRole = user.user_permissions.find(function (x) { return x.slug === 'dashboard'; });
    if (dashboardRole) {
        path = '/dashboard';
    }
    else {
        var module_1 = user.user_permissions[0];
        var submodules = user.user_permissions.filter(function (x) { return x.parent_id === module_1.id; });
        if (module_1 && !submodules.length) {
            path = "/".concat(module_1.slug);
        }
        else {
            path = "/".concat(module_1.slug, "/").concat(submodules[0].slug);
        }
    }
    return path;
};
export var checkModuleAccess = function (module, user) {
    return user.user_permissions.some(function (x) { return x.slug === module; });
};
export var checkModulePrivilegeAccess = function (module, permission) {
    var user = getLoggedInUser();
    return user.user_permissions.find(function (x) { return x.slug === module; })[permission] ? true : false;
};
export var slots = [
    '06:00 AM - 06:30 AM',
    '06:30 AM - 07:00 AM',
    '07:00 AM - 07:30 AM',
    '07:30 AM - 08:00 AM',
    '08:00 AM - 08:30 AM',
    '08:30 AM - 09:00 AM',
    '09:00 AM - 09:30 AM',
    '09:30 AM - 10:00 AM',
    '10:00 AM - 10:30 AM',
    '10:30 AM - 11:00 AM',
    '11:00 AM - 12:00 PM',
    '12:00 PM - 12:30 PM',
    '12:30 PM - 01:00 PM',
    '01:00 PM - 01:30 PM',
    '01:30 PM - 02:00 PM',
    '02:00 PM - 02:30 PM',
    '02:30 PM - 03:00 PM',
    '03:00 PM - 03:30 PM',
    '03:30 PM - 04:00 PM',
    '04:00 PM - 04:30 PM',
    '04:30 PM - 05:00 PM',
    '05:00 PM - 05:30 PM',
    '05:30 PM - 06:00 PM',
    '06:00 PM - 06:30 PM',
    '06:30 PM - 07:00 PM',
    '07:00 PM - 07:30 PM',
    '07:30 PM - 08:00 PM',
    '08:00 PM - 08:30 PM',
    '08:30 PM - 09:00 PM',
    '09:00 PM - 09:30 PM',
    '09:30 PM - 10:00 PM',
    '10:00 PM - 10:30 PM',
    '10:30 PM - 11:00 PM',
    '11:00 PM - 11:30 PM',
    '11:30 PM - 12:00 AM',
    '12:00 AM - 12:30 AM',
    '12:30 AM - 01:00 AM',
    '01:00 AM - 01:30 AM',
    '01:30 AM - 02:00 AM',
    '02:00 AM - 02:30 AM',
    '02:30 AM - 03:00 AM',
    '03:00 AM - 03:30 AM',
    '03:30 AM - 04:00 AM',
    '04:00 AM - 04:30 AM',
    '04:30 AM - 05:00 AM',
    '05:00 AM - 05:30 AM',
    '05:30 AM - 06:00 AM',
];
export var numberOfRows = [10, 25, 50, 75, 100];
// export const downloadExcel = (data: any) => {
//   const worksheet = XLSX.utils.json_to_sheet(data);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
//   XLSX.writeFile(workbook, "data.xlsx");
// };
export var nationalityList = [
    "Afghan",
    "Albanian",
    "Algerian",
    "American",
    "Andorran",
    "Angolan",
    "Antiguans",
    "Argentinean",
    "Armenian",
    "Australian",
    "Austrian",
    "Azerbaijani",
    "Bahamian",
    "Bahraini",
    "Bangladeshi",
    "Barbadian",
    "Barbudans",
    "Batswana",
    "Belarusian",
    "Belgian",
    "Belizean",
    "Beninese",
    "Bhutanese",
    "Bolivian",
    "Bosnian",
    "Brazilian",
    "British",
    "Bruneian",
    "Bulgarian",
    "Burkinabe",
    "Burmese",
    "Burundian",
    "Cambodian",
    "Cameroonian",
    "Canadian",
    "Cape Verdean",
    "Central African",
    "Chadian",
    "Chilean",
    "Chinese",
    "Colombian",
    "Comoran",
    "Congolese",
    "Costa Rican",
    "Croatian",
    "Cuban",
    "Cypriot",
    "Czech",
    "Danish",
    "Djibouti",
    "Dominican",
    "Dutch",
    "East Timorese",
    "Ecuadorean",
    "Egyptian",
    "Emirian",
    "Equatorial Guinean",
    "Eritrean",
    "Estonian",
    "Ethiopian",
    "Fijian",
    "Filipino",
    "Finnish",
    "French",
    "Gabonese",
    "Gambian",
    "Georgian",
    "German",
    "Ghanaian",
    "Greek",
    "Grenadian",
    "Guatemalan",
    "Guinea-Bissauan",
    "Guinean",
    "Guyanese",
    "Haitian",
    "Herzegovinian",
    "Honduran",
    "Hungarian",
    "I-Kiribati",
    "Icelander",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Irish",
    "Israeli",
    "Italian",
    "Ivorian",
    "Jamaican",
    "Japanese",
    "Jordanian",
    "Kazakhstani",
    "Kenyan",
    "Kittian and Nevisian",
    "Kuwaiti",
    "Kyrgyz",
    "Laotian",
    "Latvian",
    "Lebanese",
    "Liberian",
    "Libyan",
    "Liechtensteiner",
    "Lithuanian",
    "Luxembourger",
    "Macedonian",
    "Malagasy",
    "Malawian",
    "Malaysian",
    "Maldivan",
    "Malian",
    "Maltese",
    "Marshallese",
    "Mauritanian",
    "Mauritian",
    "Mexican",
    "Micronesian",
    "Moldovan",
    "Monacan",
    "Mongolian",
    "Moroccan",
    "Mosotho",
    "Motswana",
    "Mozambican",
    "Namibian",
    "Nauruan",
    "Nepalese",
    "New Zealander",
    "Nicaraguan",
    "Nigerian",
    "Nigerien",
    "North Korean",
    "Northern Irish",
    "Norwegian",
    "Omani",
    "Pakistani",
    "Palauan",
    "Panamanian",
    "Papua New Guinean",
    "Paraguayan",
    "Peruvian",
    "Polish",
    "Portuguese",
    "Qatari",
    "Romanian",
    "Russian",
    "Rwandan",
    "Saint Lucian",
    "Salvadoran",
    "Samoan",
    "San Marinese",
    "Sao Tomean",
    "Saudi",
    "Scottish",
    "Senegalese",
    "Serbian",
    "Seychellois",
    "Sierra Leonean",
    "Singaporean",
    "Slovakian",
    "Slovenian",
    "Solomon Islander",
    "Somali",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Surinamer",
    "Swazi",
    "Swedish",
    "Swiss",
    "Syrian",
    "Taiwanese",
    "Tajik",
    "Tanzanian",
    "Thai",
    "Togolese",
    "Tongan",
    "Trinidadian or Tobagonian",
    "Tunisian",
    "Turkish",
    "Tuvaluan",
    "Ugandan",
    "Ukrainian",
    "Uruguayan",
    "Uzbekistani",
    "Venezuelan",
    "Vietnamese",
    "Welsh",
    "Yemenite",
    "Zambian",
    "Zimbabwean"
];
