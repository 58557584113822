import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import leadReducer from './leads/leadSlice';
import dropdownReducer from './dropdowns/dropdownSlice';
import careerReducer from './career/careerSlice';
import testGroupReducer from './products/testGroupSlice';
import hubReducer from './hubs/hubSlice';
import labReducer from './labs/labSlice';
import testsReducer from './products/testsSlice';
import packagesReducer from './products/packagesSlice';
import customerReducer from './customer/customerSlice';
import settingReducer from './settings/settingSlice';
import orderReducer from './orders/orderSlice';
import supportticketReducer from './support/supportslice';
import couponReducer from './coupons/couponSlice';
import accountsReducer from './accounts/accountsSlice';
import qrCodesReducer from '../features/settings/QRCodes/qrSlice';
export default combineReducers({
    auth: authReducer,
    user: userReducer,
    lead: leadReducer,
    dropdown: dropdownReducer,
    career: careerReducer,
    testgroup: testGroupReducer,
    hubs: hubReducer,
    labs: labReducer,
    tests: testsReducer,
    packages: packagesReducer,
    customer: customerReducer,
    settings: settingReducer,
    orders: orderReducer,
    coupon: couponReducer,
    accounts: accountsReducer,
    supports: supportticketReducer,
    qrcode: qrCodesReducer,
});
