var TOKEN_KEY = 'user-token';
var AUTH_USER = 'auth_user';
export var login = function (value) {
    if (value.length)
        return localStorage.setItem(TOKEN_KEY, value);
    return false;
};
export var token = function () { return localStorage.getItem(TOKEN_KEY) || ''; };
export var logout = function () {
    localStorage.removeItem(AUTH_USER);
    localStorage.removeItem(TOKEN_KEY);
};
export var rememberMeToken = function () { return localStorage.getItem('remember_me_token') || ''; };
export var isUserLoggedIn = function () {
    if (localStorage.getItem(TOKEN_KEY) && localStorage.getItem(AUTH_USER)) {
        return true;
    }
    return false;
};
export var getLoggedInUser = function () {
    if (localStorage.getItem(AUTH_USER)) {
        return JSON.parse(localStorage.getItem(AUTH_USER) || '');
    }
    return false;
};
