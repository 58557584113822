var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { toast } from 'react-toastify';
var showToastMessage = function (message, type) {
    if (type === 'error') {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
    else {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
};
var initialState = {
    couponList: [],
    isCreated: false,
    isLoading: false,
    modulesList: [],
    total_orders: 0,
    last_page: 0,
    meta: {}
};
export var couponSlice = createSlice({
    name: 'coupon',
    initialState: initialState,
    reducers: {
        CouponListPending: function (state, action) {
            state.isLoading = true;
        },
        CouponListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            state.isLoading = false;
            state.isCreated = false;
            state.couponList = action.payload.data.data || [];
            state.total_orders = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.total;
            state.last_page = (_d = (_c = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.meta) === null || _d === void 0 ? void 0 : _d.last_page;
            state.meta = (_f = (_e = action === null || action === void 0 ? void 0 : action.payload) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.meta;
        },
        CouponListFailure: function (state, action) {
            var _a, _b;
            state.isLoading = false;
            showToastMessage((_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message, 'error');
        },
    },
});
export var CouponListPending = (_a = couponSlice.actions, _a.CouponListPending), CouponListSuccess = _a.CouponListSuccess, CouponListFailure = _a.CouponListFailure;
export default couponSlice.reducer;
// list of roles
export var getCouponListing = function (params, page) {
    return apiCallBegan({
        url: "/admin/coupons?page=".concat(page, "&updated_at=").concat(params === null || params === void 0 ? void 0 : params.updated_at, "&start_date=").concat(params === null || params === void 0 ? void 0 : params.start_date, "&expiry_date=").concat(params === null || params === void 0 ? void 0 : params.expiry_date, "&status=").concat(params === null || params === void 0 ? void 0 : params.status, "&search_key=").concat(params === null || params === void 0 ? void 0 : params.search_key, " "),
        method: 'GET',
        onStart: CouponListPending.type,
        onSuccess: CouponListSuccess.type,
        onError: CouponListFailure.type,
    });
};
