var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { dateRange, defaultFiltersDropDown } from '../../../utils/helpers';
import moment from 'moment';
import { SelectInput } from './Select';
import { DateRangePicker } from './DateRangePicker';
export var DateFiter = function (_a) {
    var onDateRangeSelect = _a.onDateRangeSelect, id = _a.id, defaultValue = _a.defaultValue, _b = _a.hideAll, hideAll = _b === void 0 ? false : _b;
    var _c = useState(defaultValue ? defaultValue : 'ALL'), value = _c[0], setValue = _c[1];
    var _d = useState({
        start_date: null,
        end_date: null,
    }), date = _d[0], setDate = _d[1];
    var handleFilter = function (e) {
        var _a = dateRange(e.target.value), start_date = _a[0], end_date = _a[1];
        setValue(e.target.value);
        if (start_date && end_date) {
            onDateRangeSelect({ start_date: start_date, end_date: end_date });
        }
        else {
            onDateRangeSelect({ start_date: '', end_date: '' });
        }
        setDate({
            start_date: null,
            end_date: null,
        });
    };
    var onCustomDateChange = function (e) {
        var start = e[0], end = e[1];
        setDate({ start_date: start, end_date: end });
        if (start && end) {
            onDateRangeSelect({
                start_date: moment(start).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                end_date: moment(end).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            });
        }
        else {
            onDateRangeSelect({ start_date: '', end_date: '' });
        }
    };
    return (_jsxs("div", __assign({ className: 'flex w-full flex-col sm:flex-row justify-end date_range_filter gap-3' }, { children: [_jsx("div", __assign({ className: 'sm:custom-select-input sm:w-44' }, { children: _jsx(SelectInput, { bgcolor: 'white', width: '100%', options: hideAll ? defaultFiltersDropDown.filter(function (x) { return x.id != 'ALL'; }) : defaultFiltersDropDown, handleChange: handleFilter, value: value, label: 'Select Date', name: 'Select Date Range', id: id }) })), value === 'custom' && (_jsx("div", __assign({ className: 'w-full sm:w-fit' }, { children: _jsx(DateRangePicker, { label: 'Select Range', startDate: date.start_date, endDate: date.end_date, onChange: onCustomDateChange, istodaymax: true }) })))] })));
};
