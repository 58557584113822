var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
var initialState = {
    customersList: [],
    isLoading: false,
    metadata: {
        totalCustomers: 0,
        totalPages: 0,
        total: 0,
    },
    customerData: {
        total_count: 0,
        total_active_users: 0,
        total_inactive_users: 0,
    },
    // ---------------
    membersList: [],
    addressList: [],
};
export var customersSlice = createSlice({
    name: 'customer',
    initialState: initialState,
    reducers: {
        CustomerListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            console.log('action:', action.payload);
            state.isLoading = false;
            state.customersList = action.payload.data.data || [];
            state.metadata.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.metadata.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.metadata.totalCustomers = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_customers;
        },
        CustomerListPending: function (state, action) {
            state.isLoading = true;
        },
        CustomerListFailed: function (state) {
            state.isLoading = false;
        },
        MemberListSuccess: function (state, action) {
            var _a, _b;
            console.log('action:', action.payload.data.data);
            state.isLoading = false;
            state.membersList = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data;
        },
        MemberListFailed: function (state, action) {
            state.isLoading = false;
        },
        MemberListPending: function (state, action) {
            state.isLoading = true;
        },
        AddressListSuccess: function (state, action) {
            var _a, _b;
            state.isLoading = false;
            state.addressList = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.data;
        },
        AddressListFailed: function (state, action) {
            state.isLoading = false;
        },
        AddressListPending: function (state, action) {
            state.isLoading = true;
        },
        // Customr analytics
        CustomerDataSuccess: function (state, action) {
            state.customerData = action.payload.data;
            console.log('action.payload.data:', action.payload.data);
        },
        CustomerDataFailed: function (state, action) { },
        CustomerDataPending: function (state, action) { },
    },
});
export var MemberListSuccess = (_a = customersSlice.actions, _a.MemberListSuccess), MemberListFailed = _a.MemberListFailed, MemberListPending = _a.MemberListPending, CustomerListPending = _a.CustomerListPending, CustomerListSuccess = _a.CustomerListSuccess, CustomerListFailed = _a.CustomerListFailed, AddressListSuccess = _a.AddressListSuccess, AddressListFailed = _a.AddressListFailed, AddressListPending = _a.AddressListPending, CustomerDataSuccess = _a.CustomerDataSuccess, CustomerDataFailed = _a.CustomerDataFailed, CustomerDataPending = _a.CustomerDataPending;
export default customersSlice.reducer;
export var fetchCustomersList = function (data) {
    return apiCallBegan({
        url: "/admin/customers?search_key=".concat(data.search_key.trim(), "&start_date=").concat(data.start_date, "&end_date=").concat(data.end_date, "&page=").concat(data.page, "&last_login_in=").concat(data.last_login_in, "&limit=").concat(data === null || data === void 0 ? void 0 : data.limit),
        method: 'GET',
        onSuccess: CustomerListSuccess.type,
        onError: CustomerListFailed.type,
        onStart: CustomerListPending.type,
    });
};
export var fetchCustomerMemberList = function (data) {
    return apiCallBegan({
        url: "/admin/customers/family-members?customer_id=".concat(data.id, "&page=").concat(data.page),
        method: 'GET',
        onSuccess: MemberListSuccess.type,
        onError: MemberListFailed.type,
        onStart: MemberListPending.type,
    });
};
export var fetchCustomerAddressList = function (data) {
    return apiCallBegan({
        url: "/admin/customers/addresses?customer_id=".concat(data.id, "&page=").concat(data.page),
        method: 'GET',
        onSuccess: AddressListSuccess.type,
        onError: AddressListFailed.type,
        onStart: AddressListPending.type,
    });
};
export var fetchCustomerAnalytics = function () {
    return apiCallBegan({
        url: "/admin/customers/stats",
        method: 'GET',
        onSuccess: CustomerDataSuccess.type,
        onError: CustomerDataFailed.type,
        onStart: CustomerDataPending.type,
    });
};
