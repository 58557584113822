var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
var PieChart = function (_a) {
    var leads = _a.leads, loading = _a.loading;
    var _b = useState({}), chartData = _b[0], setChartData = _b[1];
    var _c = useState({}), chartOptions = _c[0], setChartOptions = _c[1];
    useEffect(function () {
        setChartData({
            labels: ['Converted', 'Not Converted'],
            datasets: [
                {
                    data: [leads === null || leads === void 0 ? void 0 : leads.converted_lead, leads === null || leads === void 0 ? void 0 : leads.not_converted_lead],
                    backgroundColor: ['#FFC453', '#EAEDF0'],
                    hoverBackgroundColor: ['#FFC453', '#EAEDF0'],
                    borderWidth: [0, 5],
                    spacing: 0,
                },
            ],
        });
        setChartOptions({
            cutoutPercentage: 80,
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                },
            },
            legend: {
                display: false,
            },
            tooltips: {
                enabled: true,
            },
            hover: {
                mode: null,
            },
            elements: {
                arc: {
                    borderWidth: 0,
                },
            },
        });
    }, [leads]);
    return (_jsx(_Fragment, { children: loading ? ('') : (_jsxs("div", __assign({ className: 'flex flex-col gap-4' }, { children: [_jsxs("div", __assign({ style: { position: 'relative', width: '300px', height: '300px' } }, { children: [_jsxs("div", __assign({ style: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            } }, { children: [_jsx("p", __assign({ className: 'text-[#141C4C] text-xl font-nunitoBold text-center' }, { children: "Total Leads" })), _jsx("p", __assign({ className: 'text-[#6DC4EA] font-nunitoBold text-3xl text-center' }, { children: (leads === null || leads === void 0 ? void 0 : leads.total_leads) || 0 }))] })), _jsx(Doughnut, { data: chartData, options: chartOptions })] })), _jsxs("div", { children: [_jsxs("div", __assign({ className: 'grid grid-cols-3 justify-center items-center' }, { children: [_jsx("span", { className: 'w-[55px] h-[17px] bg-[#EAEDF0] border flex justify-center' }), _jsx("p", __assign({ className: 'text-[#141C4C] text-[14px] font-nunitoRegular flex justify-center' }, { children: "Not Converted" })), _jsx("p", __assign({ className: 'text-[#141C4C] text-[20px] font-nunitoBold flex justify-end' }, { children: ((leads === null || leads === void 0 ? void 0 : leads.total_leads) - (leads === null || leads === void 0 ? void 0 : leads.total_converted)) || 0 }))] })), _jsxs("div", __assign({ className: 'grid grid-cols-3 justify-center items-center' }, { children: [_jsx("span", { className: 'w-[55px] h-[17px] bg-[#FFC453] border flex justify-center' }), _jsx("p", __assign({ className: 'text-[#141C4C] text-[14px] font-nunitoRegular flex justify-center' }, { children: "Converted" })), _jsx("p", __assign({ className: 'text-[#141C4C] text-[20px] font-nunitoBold flex justify-end' }, { children: (leads === null || leads === void 0 ? void 0 : leads.total_converted) || 0 }))] }))] })] }))) }));
};
export default PieChart;
