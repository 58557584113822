var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import UserRoleTable from './UserRoleTable';
import PopUp from './UserRolePopup';
import CustomButton from './common/Button';
import Plus from '../../../../../assets/icons/CreateIcons/PlusIcon.svg';
import Validator from 'validatorjs';
import { checkModulePrivilegeAccess, showToastMessage } from '../../../../../utils/helpers';
import { useDispatch } from 'react-redux';
import { createAdminRole, getAdminRolesList, updateAdminRolePermissions, } from '../../../../../features/settings/settingSlice';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../../../utils/axios';
var UserRoleInfo = function (_a) {
    var custId = _a.custId;
    var dispatch = useDispatch();
    var rolesList = useSelector(function (state) { return state.settings.rolesList; });
    var deleteStatus = useSelector(function (state) { return state.settings.deleteStatus; });
    var isCreated = useSelector(function (state) { return state.settings.isCreated; });
    var _b = useState({
        name: '',
    }), formErrors = _b[0], setFormErrors = _b[1];
    // const modules_list = useSelector((state: any) => state.settings.modulesList)
    var fetchRolesList = function () {
        axiosInstance
            .get('/admin/roles')
            .then(function (response) {
            var thisData = response.data;
            setModulesList(thisData.modules);
        })
            .catch(function (err) {
            var _a = err.response.data, errors = _a.errors, message = _a.message;
            var erroMsg = errors[Object.keys(errors)[0]] || message;
            showToastMessage(erroMsg, 'error');
        });
    };
    // to get modules list
    useEffect(function () {
        dispatch(getAdminRolesList());
        fetchRolesList();
    }, []);
    useEffect(function () {
        dispatch(getAdminRolesList());
    }, [deleteStatus, isCreated]);
    // handeling popup
    var _c = useState({
        isopen: false,
        type: '',
    }), isMemberPopup = _c[0], setIsMemberPopup = _c[1];
    var _d = useState([]), modulesList = _d[0], setModulesList = _d[1];
    var _e = useState({
        name: '',
        role: 'Manager',
        id: '',
        is_manager: false,
        list: [],
    }), params = _e[0], setParams = _e[1];
    var _f = useState({
        open_add: false,
        open_delete: false,
        edit: false,
        id: '',
    }), dialogMeta = _f[0], setDialogMeta = _f[1];
    var addNewRole = function () {
        setParams(__assign(__assign({}, params), { name: '', role: 'Manager', id: '', is_manager: false, list: [] }));
        var options = __spreadArray([], modulesList, true);
        options = options
            .filter(function (x) { return x.parent_id == null; })
            .map(function (x) {
            x['is_checked'] = false;
            x['children'] = modulesList
                .filter(function (y) { return y.parent_id === x.id; })
                .map(function (z) {
                z['is_read'] = false;
                z['is_write'] = false;
                z['is_delete'] = false;
                z['is_update'] = false;
                z['is_checked'] = false;
                return z;
            });
            return x;
        });
        setParams(__assign(__assign({}, params), { name: '', id: '', list: options, is_manager: true, role: 'Manager' }));
        setDialogMeta(__assign(__assign({}, dialogMeta), { open_add: true }));
    };
    var handleFormChanges = function (e) {
        setParams(__assign(__assign({}, params), { is_manager: e.target.value === 'Manager' ? true : false }));
        setFormErrors({
            name: ""
        });
    };
    var handleClose = function () {
        var list = __spreadArray([], params.list, true);
        list.map(function (item) {
            item['is_read'] = false;
            item['is_write'] = false;
            item['is_delete'] = false;
            item['is_update'] = false;
            item['is_checked'] = false;
        });
        setParams(__assign(__assign({}, params), { name: '', id: '', list: list, is_manager: true, role: 'Manager' }));
        setDialogMeta(false);
    };
    var addPermission = function (array, list) {
        array.push({
            module_id: list.id,
            is_read: list.is_read,
            is_write: list.is_write,
            is_update: list.is_update,
            is_delete: list.is_delete,
        });
        return array;
    };
    useEffect(function () {
        if (isCreated) {
            handleClose();
        }
    }, [isCreated]);
    var submit = function () {
        var rules = {
            name: ['required', 'regex:/^[A-Za-z]+$/', 'max:50'],
        };
        var validation = new Validator(params, rules);
        if (validation.fails()) {
            var fieldErrors_1 = {};
            Object.keys(validation.errors.errors).forEach(function (key) {
                fieldErrors_1[key] = validation.errors.errors[key][0];
            });
            var err = Object.keys(fieldErrors_1);
            if (err.length) {
                var input = document.querySelector("input[name=".concat(err[0], "]"));
                if (input) {
                    input.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'start',
                    });
                }
            }
            setFormErrors(fieldErrors_1);
            return false;
        }
        var is_checked = params.list.some(function (x) { return x.is_checked; });
        if (!is_checked) {
            showToastMessage('Please Select atleast one module!', 'error');
            return;
        }
        var permissions = [];
        for (var _i = 0, _a = params.list; _i < _a.length; _i++) {
            var list = _a[_i];
            if (list.is_checked) {
                permissions = addPermission(permissions, list);
                var children = list.children.filter(function (x) { return x.is_checked; });
                if (children.length) {
                    for (var _b = 0, children_1 = children; _b < children_1.length; _b++) {
                        var child = children_1[_b];
                        permissions = addPermission(permissions, child);
                    }
                }
            }
        }
        var payload = {
            name: params.name,
            is_manager: params.is_manager,
            permissions: permissions,
        };
        if (params.id) {
            // updateRole(params.id, payload)
            dispatch(updateAdminRolePermissions(params.id, payload));
        }
        else {
            dispatch(createAdminRole(payload));
            // createRole(payload)
        }
    };
    var handleCheckBoxSel = function (list, index, checked) {
        list[index].is_checked = checked;
        list[index]['is_write'] = checked;
        list[index]['is_update'] = checked;
        list[index]['is_read'] = checked;
        list[index]['is_delete'] = checked;
        list[index].children = list[index].children.map(function (x) {
            x['is_checked'] = checked;
            x['is_write'] = checked;
            x['is_update'] = checked;
            x['is_read'] = checked;
            x['is_delete'] = checked;
            return x;
        });
    };
    var handleRootChange = function (e, item) {
        var _a = e.target, name = _a.name, checked = _a.checked;
        var list = __spreadArray([], params.list, true);
        var index = list.findIndex(function (x) { return x.id == item.id; });
        if (index != -1) {
            if (checked && ['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                list[index].is_checked = checked;
                list[index][name] = checked;
            }
            else if (checked) {
                handleCheckBoxSel(list, index, checked);
            }
            if (!checked && ['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                list[index][name] = checked;
                var item_1 = list[index];
                if (!item_1.is_write && !item_1.is_update && !item_1.is_read && !item_1.is_delete) {
                    list[index].is_checked = checked;
                }
            }
            if (!checked && !['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                handleCheckBoxSel(list, index, checked);
            }
        }
        setParams(__assign(__assign({}, params), { list: list }));
    };
    var handleNestedCheckBoxSel = function (list, index, checked, child) {
        var subIndex = list[index].children.findIndex(function (x) { return x.id === child.id; });
        if (subIndex != -1) {
            list[index].children[subIndex].is_checked = checked;
            list[index].children[subIndex]['is_write'] = checked;
            list[index].children[subIndex]['is_update'] = checked;
            list[index].children[subIndex]['is_read'] = checked;
            list[index].children[subIndex]['is_delete'] = checked;
        }
        var is_child_checked = list[index].children.some(function (x) { return x.is_checked; });
        list[index].is_checked = is_child_checked;
    };
    var handleChildChange = function (e, item, child) {
        var _a = e.target, name = _a.name, checked = _a.checked;
        var list = __spreadArray([], params.list, true);
        var index = list.findIndex(function (x) { return x.id == item.id; });
        if (index != -1) {
            if (checked && ['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                list[index].is_checked = checked;
                var subIndex = list[index].children.findIndex(function (x) { return x.id === child.id; });
                if (subIndex != -1) {
                    list[index].children[subIndex].is_checked = checked;
                    list[index].children[subIndex][name] = checked;
                }
            }
            else if (checked) {
                handleNestedCheckBoxSel(list, index, checked, child);
            }
            if (!checked && ['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                var subIndex = list[index].children.findIndex(function (x) { return x.id === child.id; });
                if (subIndex != -1) {
                    list[index].children[subIndex][name] = checked;
                    var item_2 = list[index].children[subIndex];
                    if (!item_2.is_write && !item_2.is_update && !item_2.is_read && !item_2.is_delete) {
                        list[index].children[subIndex].is_checked = checked;
                    }
                    var is_child_checked = list[index].children.some(function (x) { return x.is_checked; });
                    list[index].is_checked = is_child_checked;
                }
            }
            if (!checked && !['is_write', 'is_update', 'is_read', 'is_delete'].includes(name)) {
                handleNestedCheckBoxSel(list, index, checked, child);
            }
        }
        setParams(__assign(__assign({}, params), { list: list }));
    };
    var handleChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setParams(__assign(__assign({}, params), (_a = {}, _a[name] = value, _a)));
        setFormErrors({
            name: ''
        });
    };
    var onRowEdit = function (row) {
        var options = __spreadArray([], modulesList, true);
        options = options
            .filter(function (x) { return x.parent_id == null; })
            .map(function (x) {
            var root_ele = row.permissions.find(function (y) { return y.module_id === x.id; });
            x['is_checked'] =
                (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_read) ||
                    (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_write) ||
                    (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_delete) ||
                    (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_update) ||
                    false;
            x['is_read'] = (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_read) || false;
            x['is_write'] = (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_write) || false;
            x['is_delete'] = (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_delete) || false;
            x['is_update'] = (root_ele === null || root_ele === void 0 ? void 0 : root_ele.is_update) || false;
            x['children'] = modulesList
                .filter(function (y) { return y.parent_id === x.id; })
                .map(function (z) {
                var child_ele = row.permissions.find(function (y) { return y.module_id === z.id; });
                z['is_checked'] =
                    (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_read) ||
                        (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_write) ||
                        (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_delete) ||
                        (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_update) ||
                        false;
                z['is_read'] = (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_read) || false;
                z['is_write'] = (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_write) || false;
                z['is_delete'] = (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_delete) || false;
                z['is_update'] = (child_ele === null || child_ele === void 0 ? void 0 : child_ele.is_update) || false;
                return z;
            });
            return x;
        });
        setParams(__assign(__assign({}, params), { list: options, name: row.name, role: row.is_manager ? 'Manager' : 'Executive', id: row.id }));
        setDialogMeta(__assign(__assign({}, dialogMeta), { edit: true }));
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: 'w-full rounded flex flex-col gap-6' }, { children: _jsxs("div", __assign({ className: 'default_container bg-white ' }, { children: [_jsxs("div", __assign({ className: 'flex justify-between item-center mb-2' }, { children: [_jsxs("div", { children: [_jsx("p", __assign({ className: 'w-24 sm:w-fit subheading' }, { children: "User Role" })), _jsx("p", __assign({ className: 'lg:text-sm text-xs' }, { children: "Add/ Remove Items to the List of User Role." }))] }), checkModulePrivilegeAccess('settings', 'is_write') &&
                                    _jsx(CustomButton, __assign({ onClick: function (e) {
                                            addNewRole();
                                        }, 
                                        // width='w-full'
                                        variant: 'outlined', size: 'large', borderRadius: '8px', icon: _jsx("img", { src: Plus, alt: '' }) }, { children: "Add User Role" }))] })), _jsx("div", __assign({ className: ' flex flex-col gap-4  justify-center ' }, { children: _jsx("div", { children: _jsx(UserRoleTable, { dataList: rolesList, onRowEdit: onRowEdit }) }) })), _jsx("br", {})] })) })), _jsx(PopUp, { open: dialogMeta.open_add, handleClose: handleClose, title: 'Add New User Role', type: 'create', name: '', params: params, setParams: handleFormChanges, submit: submit, handleRootChange: handleRootChange, handleChildChange: handleChildChange, handleChange: handleChange, formErrors: formErrors }), _jsx(PopUp, { open: dialogMeta.edit, handleClose: handleClose, title: 'Update User Role', type: 'update', name: '', params: params, setParams: handleFormChanges, submit: submit, handleRootChange: handleRootChange, handleChildChange: handleChildChange, handleChange: handleChange, formErrors: formErrors })] }));
};
export default UserRoleInfo;
