var _a;
import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from '../middleware/api-creators';
import { showToastMessage } from './../../utils/helpers';
var initialState = {
    ApplicationsList: [],
    isLoading: false,
    metadata: {
        totalApplications: 0,
        totalPages: 0,
        total: 0,
    },
    // ---------------------
    JobsList: [],
    jobsmeta: {
        totalJobs: 0,
        totalPages: 0,
        total: 0,
    },
    // ----------------------
    createStatus: false,
    deleteStatus: false,
    updateStatus: false,
    status: false,
    job_list: [],
    job_by_id: {},
    meta: {},
    total_jobs: 0,
    job_applicants: [],
    total_applicants: 0,
};
export var careerSlice = createSlice({
    name: 'career',
    initialState: initialState,
    reducers: {
        resetLoading: function (state) {
            state.isLoading = false;
        },
        resetSuccess: function (state) {
            state.deleteStatus = false;
            state.updateStatus = false;
        },
        // for getting
        JobListPending: function (state, action) {
            state.isLoading = true;
        },
        JobListSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            state.createStatus = false;
            state.isLoading = false;
            state.JobsList = action.payload.data.data;
            state.jobsmeta.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.jobsmeta.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.jobsmeta.totalJobs = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_applicants;
            // showToastMessage(action.payload.data.message, 'success')
        },
        JobListFailure: function (state, action) {
            showToastMessage(action.payload.data.message, 'error');
        },
        JobPending: function (state, action) { },
        JobSuccess: function (state, action) {
            var _a, _b;
            state.job_by_id = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data;
            console.log('job-opening by id', (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data);
        },
        JobFailure: function (state, action) {
            // showToastMessage(action.payload.data.message, 'error')
        },
        DeletePending: function (state, action) { },
        DeleteSuccess: function (state, action) {
            var _a, _b, _c;
            showToastMessage(action.payload.data.message, 'success');
            console.log('deleted job opening', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            state.deleteStatus = (_c = (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.status;
        },
        DeleteFailure: function (state, action) {
            var _a, _b, _c;
            state.deleteStatus = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.status;
            console.log('delete job opening by id', (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data);
            showToastMessage("You can't delete the role. Its associate with multiple applicants", 'error');
        },
        CreationPending: function (state, action) { },
        CreationSuccess: function (state, action) {
            var _a;
            state.createStatus = true;
            console.log('job opening created', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage(action.payload.data.message, 'success');
        },
        CreationFailure: function (state, action) {
            // state.createStatus = false
            showToastMessage(action.payload.data.message, 'error');
        },
        // update job opening by id
        UpdateJobPending: function (state, action) { },
        UpdateJobSuccess: function (state, action) {
            var _a;
            console.log('edit job opening', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
            showToastMessage(action.payload.data.message, 'success');
        },
        UpdateJobFailure: function (state, action) {
            showToastMessage(action.payload.data.message, 'error');
        },
        JobStatusPending: function (state, action) { },
        JobStatusSuccess: function (state, action) {
            state.updateStatus = true;
            showToastMessage(action.payload.data.message, 'success');
        },
        JobStatusFailure: function (state, action) {
            state.updateStatus = false;
            showToastMessage(action.payload.data.message, 'error');
        },
        JobApplicantsPending: function (state, action) {
            state.isLoading = true;
        },
        JobApplicantsSuccess: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            state.isLoading = false;
            state.ApplicationsList = action.payload.data.data;
            state.metadata.totalPages = (_b = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data.meta) === null || _b === void 0 ? void 0 : _b.last_page;
            state.metadata.total = (_e = (_d = (_c = action === null || action === void 0 ? void 0 : action.payload) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.meta) === null || _e === void 0 ? void 0 : _e.total;
            state.metadata.totalApplications = (_f = action === null || action === void 0 ? void 0 : action.payload) === null || _f === void 0 ? void 0 : _f.total_applicants;
        },
        JobApplicantsFailure: function (state, action) {
            state.isLoading = false;
            showToastMessage(action.payload.data.message, 'error');
        },
        DeleteApplicantPending: function (state, action) { },
        DeleteApplicantSuccess: function (state, action) {
            var _a, _b;
            // showToastMessage(action.payload.data.message, 'success')
            state.deleteStatus = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.status;
            console.log('delete applicant by id', (_b = action === null || action === void 0 ? void 0 : action.payload) === null || _b === void 0 ? void 0 : _b.data);
        },
        DeleteApplicantFailure: function (state, action) {
            var _a;
            state.deleteStatus = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.status;
            showToastMessage(action.payload.data.message, 'error');
        },
        ApplicantPending: function (state, action) { },
        ApplicantSuccess: function (state, action) {
            var _a;
            console.log('get applicant by id', (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a.data);
        },
        ApplicantFailure: function (state, action) {
            showToastMessage(action.payload.data.message, 'error');
        },
    },
});
export var resetSuccess = (_a = careerSlice.actions, _a.resetSuccess), JobListPending = _a.JobListPending, JobListSuccess = _a.JobListSuccess, JobListFailure = _a.JobListFailure, JobPending = _a.JobPending, JobSuccess = _a.JobSuccess, JobFailure = _a.JobFailure, DeletePending = _a.DeletePending, DeleteSuccess = _a.DeleteSuccess, DeleteFailure = _a.DeleteFailure, CreationPending = _a.CreationPending, CreationSuccess = _a.CreationSuccess, CreationFailure = _a.CreationFailure, UpdateJobPending = _a.UpdateJobPending, UpdateJobSuccess = _a.UpdateJobSuccess, UpdateJobFailure = _a.UpdateJobFailure, JobStatusPending = _a.JobStatusPending, JobStatusSuccess = _a.JobStatusSuccess, JobStatusFailure = _a.JobStatusFailure, JobApplicantsPending = _a.JobApplicantsPending, JobApplicantsSuccess = _a.JobApplicantsSuccess, JobApplicantsFailure = _a.JobApplicantsFailure, DeleteApplicantPending = _a.DeleteApplicantPending, DeleteApplicantSuccess = _a.DeleteApplicantSuccess, DeleteApplicantFailure = _a.DeleteApplicantFailure, ApplicantPending = _a.ApplicantPending, ApplicantSuccess = _a.ApplicantSuccess, ApplicantFailure = _a.ApplicantFailure;
export default careerSlice.reducer;
// job opening dispatch functions
//get all job openings with params filter
export var fetchJobOpening = function (params, page) {
    return apiCallBegan({
        url: "/admin/job-openings?page=".concat(page, "&start_date=").concat(params.start_date, "&end_date=").concat(params.end_date, "&search_key=").concat(params.search_key),
        method: 'GET',
        onStart: JobListPending.type,
        onSuccess: JobListSuccess.type,
        onError: JobListFailure.type,
    });
};
// get job opening by its id
export var fetchJobById = function (id) {
    return apiCallBegan({
        url: "/admin/job-openings/".concat(id),
        method: 'GET',
        onStart: JobPending.type,
        onSuccess: JobSuccess.type,
        onError: JobFailure.type,
    });
};
//delete job opening
export var deleteJobById = function (id) {
    return apiCallBegan({
        url: "/admin/job-openings/".concat(id),
        method: 'DELETE',
        onStart: DeletePending.type,
        onSuccess: DeleteSuccess.type,
        onError: DeleteFailure.type,
    });
};
//create job opening
export var createJobOpening = function (data) {
    return apiCallBegan({
        url: '/admin/job-openings',
        method: 'POST',
        data: data,
        onStart: CreationPending.type,
        onSuccess: CreationSuccess.type,
        onError: CreationFailure.type,
    });
};
//edit job opening PUT request
export var editJobOpening = function (data, id) {
    return apiCallBegan({
        url: "/admin/job-openings/".concat(id),
        method: 'PUT',
        data: data,
        onStart: UpdateJobPending.type,
        onSuccess: UpdateJobSuccess.type,
        onError: UpdateJobFailure.type,
    });
};
//enable or disable job status
export var updateJobStatus = function (id, data) {
    return apiCallBegan({
        url: "/admin/job-openings/update-status/".concat(id),
        method: 'PATCH',
        data: data,
        onStart: JobStatusPending.type,
        onSuccess: JobStatusSuccess.type,
        onError: JobStatusFailure.type,
    });
};
// job applicants dispatch functions
//get list of job applicants
export var fetchApplications = function (params, page) {
    if (page === void 0) { page = 1; }
    return apiCallBegan({
        url: "/admin/job-applicants?page=".concat(page, "&start_date=").concat(params.start_date, "&end_date=").concat(params.end_date, "&search_key=").concat(params.search_key),
        method: 'GET',
        onStart: JobApplicantsPending.type,
        onSuccess: JobApplicantsSuccess.type,
        onError: JobApplicantsFailure.type,
    });
};
//delete job applicant by id
export var deleteApplicant = function (id) {
    return apiCallBegan({
        url: "/admin/job-applicants/".concat(id),
        method: 'DELETE',
        onStart: DeleteApplicantPending.type,
        onSuccess: DeleteApplicantSuccess.type,
        onError: DeleteApplicantFailure.type,
    });
};
//get job applicant by id
export var getApplicantById = function (id) {
    return apiCallBegan({
        url: "/admin/job-applicants/".concat(id),
        method: 'GET',
        onStart: JobApplicantsPending.type,
        onSuccess: JobApplicantsSuccess.type,
        onError: JobApplicantsFailure.type,
    });
};
